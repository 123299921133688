<script lang="ts">
	import { _ } from 'svelte-i18n'
	import type { FeedbackRating } from '@lovoo-dna/shared'

	export let rating: FeedbackRating | null
</script>

{#if rating}
	<div {...$$restProps}>
		<small> {$_('common.strengths')} </small>
		<p class="mb3">{rating.pros}</p>
		<small> {$_('common.potential')} </small>
		<p>{rating.cons}</p>
	</div>
{/if}

<style>
	p {
		margin-top: 0.125rem;
		text-align: justify;
	}
</style>
