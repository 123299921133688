<script lang="ts">
	import type { Trend } from '@lovoo-dna/shared'
	import Bad from 'carbon-icons-svelte/lib/WarningFilled24'
	import Good from 'carbon-icons-svelte/lib/CheckmarkFilled24'

	import IconSwitcher from './IconSwitcher.svelte'

	export let trend: Trend
	let delta = ''

	$: {
		delta = trend.delta.toFixed(1) + '%'
		if (trend.delta >= 0) delta = '+' + delta
	}
</script>

<div class="flex items-center">
	<div style="color: var(--cds-support-{trend.positive ? '02' : '03'})">
		<IconSwitcher value={trend.positive} yes={Good} no={Bad} />
	</div>
	<div class="ml2">{delta}</div>
</div>
