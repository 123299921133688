<script lang="ts">
	import { get } from 'svelte/store'
	import { Form, Button } from 'carbon-components-svelte'
	import Save20 from 'carbon-icons-svelte/lib/Save20'
	import dayjs from 'dayjs'

	import DatePicker from '../../../components/DatePicker.svelte'
	import InlineError from '../../../components/InlineError.svelte'

	import { Timestamp } from '../../../firebase'
	import { empty, add } from '../../../store/rounds'
	import { groupOfUser } from '../../../store/groups'
	import { RoundState } from '@lovoo-dna/shared'

	export let id: string
	export let done: Function

	let error: Error | null = null
	let round = empty(id, '')
	let date: Date

	async function submit(e: Event) {
		e.preventDefault()
		try {
			error = null
			const group = get(groupOfUser(id))
			if (!group) throw new Error('User is not in a group')
			const target = dayjs(date).endOf('day')
			round.created = Timestamp.fromMillis(target.valueOf())
			round.state = dayjs().isSame(target, 'day') ? RoundState.SelectEmployee : RoundState.Created
			round.deadline = Timestamp.fromMillis(target.add(1, 'week').valueOf())
			round.group = group.id
			await add(round)
			done()
		} catch (e) {
			console.error(e)
			error = e
		}
	}
</script>

<Form on:submit={submit}>
	<DatePicker bind:date />
	<br />
	<br />
	<Button type="submit" icon={Save20}>Create</Button>
	<InlineError {error} />
</Form>
