<script lang="ts">
	import { Button, Toggle } from 'carbon-components-svelte'
	import { _ } from 'svelte-i18n'
	import Right from 'carbon-icons-svelte/lib/ArrowRight32'

	import Gif from './Gif.svelte'
	import type { FeedbackResult } from '@lovoo-dna/shared'

	export let forward: () => void
	export let result: FeedbackResult

	const gifs = [
		'daOrHfoE9sxowLnuRJ',
		'Ln2dAW9oycjgmTpjX9',
		'QuOpCszzjdjP5AUylg',
		'QvwCVnX9DWdlHCnix5',
		'efPA2YD9BFWS30GJ5v',
	]
</script>

<Gif {gifs} />
<h2 class="mt3">Intro</h2>
<p class="mb3">
	{@html $_('dna.misc.introText')}
</p>
<div class="flex justify-end">
	<div>
		<Toggle
			class="mv3"
			labelText={$_('actions.submitAnonymously')}
			labelA={$_('common.no')}
			labelB={$_('common.yes')}
			bind:toggled={result.anonymous}
		/>
		<br />
		<Button icon={Right} on:click={forward}>{$_('common.understood')}</Button>
	</div>
</div>

<style>
	p :global(ul) {
		list-style: disc;
		padding-left: 1rem;
	}
	p :global(ul li) {
		margin-top: 1em;
	}
</style>
