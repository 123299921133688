<script lang="ts">
	import { DataTable, Toolbar, ToolbarContent, ToolbarSearch, Button } from 'carbon-components-svelte'
	import Delete32 from 'carbon-icons-svelte/lib/Delete32'
	import { _ } from 'svelte-i18n'

	import { all, remove } from '../../../store/groups'
	import { getUserName } from '../../../store/users'
	import { FuzzySearch } from '../../../lib/utils'

	import GroupForm from '../components/GroupForm.svelte'
	import Actions from '../components/Actions.svelte'
	import Modal from '../../../components/Modal.svelte'
	import Spinner from '../../../components/Spinner.svelte'

	const headers = [
		{ key: 'name', value: 'Name' },
		{ key: 'manager', value: 'Manager' },
		{ key: 'observers', value: 'Observers' },
		{ key: 'members', value: 'Members' },
		{ key: 'actions', empty: true },
	]

	let needle = ''
	$: items =
		$all?.map((group) => ({
			id: group.id,
			name: group.name,
			manager: $getUserName(group.manager),
			observers: $getUserName(group.observers).join(', '),
			members: $getUserName(group.members).join(', '),
		})) || []
	$: idx = new FuzzySearch(items, ['name'])
	$: rows = idx.search(needle)

	// Edit modal
	let editOpen = false
	let editId: string | null = null

	// Delete modal
	let delId: string | null = null
	let delOpen = false
	async function delFN() {
		if (delId) await remove(delId)
		delOpen = false
	}

	function del(id: string) {
		delId = id
		delOpen = true
	}

	function edit(id: string) {
		editId = id
		editOpen = true
	}
</script>

<Modal bind:open={editOpen} title={$_(editId ? 'actions.updateGroup' : 'actions.createGroup')} let:done let:setBlocked>
	<GroupForm id={editId} {done} {setBlocked} />
</Modal>

<Modal bind:open={delOpen} title={$_('actions.deleteGroup')}>
	<p>{$_('descriptions.permanentActionWarning')}</p>
	<br />
	<Button kind="danger" icon={Delete32} on:click={delFN}>{$_('actions.delete')}</Button>
</Modal>

{#if rows}
	<DataTable sortable {headers} {rows}>
		<Toolbar>
			<ToolbarContent>
				<ToolbarSearch bind:value={needle} />
				<Button
					on:click={() => {
						editId = null
						editOpen = true
					}}>{$_('actions.create')}</Button
				>
			</ToolbarContent>
		</Toolbar>

		<span slot="cell" let:cell let:row>
			{#if cell.key === 'actions'}
				<Actions data={row.id} {del} {edit} />
			{:else}{cell.value}{/if}
		</span>
	</DataTable>
{:else}
	<Spinner />
{/if}
