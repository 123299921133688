<script lang="ts">
	import { _ } from 'svelte-i18n'

	import { ownManagerOrObserverGroups } from '../../../../store/groups'
	import TeamDna from './TeamDNA.svelte'
	import BackButton from '../../../../components/BackButton.svelte'

	$: groups = $ownManagerOrObserverGroups
</script>

{#if groups && groups.length}
	<section>
		<BackButton />
		<h2 class="mt5 mb4">{$_('common.teams')}</h2>
		<TeamDna />
		<BackButton />
	</section>
{/if}
