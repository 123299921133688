import { derived } from 'svelte/store';
import { RoundState, RoundValidator } from '@lovoo-dna/shared';
import dayjs from 'dayjs';
import { query, orderBy, where, addDoc, deleteDoc, updateDoc, doc } from 'firebase/firestore';
import { getCollection, Timestamp } from '../firebase';
import { createStore } from '../firebase/adapter';
import { me } from './auth';
import { ownManagerGroups, ownManagerOrObserverGroups } from './groups';
import { genValidator } from '../lib/utils';
import { universalInClause } from './utils';
export const collection = getCollection('rounds');
const collectionSorted = query(collection, orderBy('created', 'desc'));
export function empty(employee, group) {
    return {
        employee,
        group,
        created: Timestamp.now(),
        deadline: Timestamp.fromMillis(dayjs().add(1, 'week').endOf('day').valueOf()),
        state: RoundState.SelectEmployee,
        discussed: false,
        comment: '',
        summary: null,
    };
}
const validate = genValidator(RoundValidator);
// Queries & Derived
export const all = createStore(collectionSorted);
export const one = derived(all, ($all) => (id) => ($all === null || $all === void 0 ? void 0 : $all.find((round) => round.id === id)) || null);
export const getRoundsForUser = derived(all, ($all) => (id) => ($all === null || $all === void 0 ? void 0 : $all.filter((round) => round.employee === id)) || null);
export const getCompletedRoundsForUser = derived(getRoundsForUser, ($rounds) => (id) => { var _a; return ((_a = $rounds(id)) === null || _a === void 0 ? void 0 : _a.filter((r) => r.state === RoundState.Completed)) || null; });
export const ownRounds = derived(me, ($me, set) => {
    if ($me) {
        const q = query(collectionSorted, where('employee', '==', $me.id));
        createStore(q).subscribe(set);
    }
});
export const ownSelectRound = derived(ownRounds, ($rounds) => ($rounds === null || $rounds === void 0 ? void 0 : $rounds.filter((round) => round.state === RoundState.SelectEmployee)) || null);
export const ownCompletedRounds = derived(ownRounds, ($rounds) => ($rounds === null || $rounds === void 0 ? void 0 : $rounds.filter((round) => round.state === RoundState.Completed)) || null);
export const ownManagerOrObserverRounds = derived(ownManagerOrObserverGroups, ($groups, set) => {
    if (!$groups)
        set(null);
    else {
        const groupIDs = $groups.map((group) => group.id);
        if (groupIDs.length) {
            universalInClause(collectionSorted, 'group', groupIDs).subscribe(set);
        }
        else
            set([]);
    }
}, null);
export const oneOwnManagerOrObserverRounds = derived(ownManagerOrObserverRounds, (rounds) => (id) => (rounds === null || rounds === void 0 ? void 0 : rounds.find((r) => r.id === id)) || null);
export const ownManagerSelectRounds = derived([ownManagerOrObserverRounds, ownManagerGroups], ([rounds, groups]) => {
    if (!groups || !rounds)
        return null;
    const ids = groups.map((g) => g.id);
    return rounds.filter((r) => r.state === RoundState.SelectManager).filter((r) => r.group && ids.includes(r.group));
});
export const oneOwnManagerOrObserverCompletedRoundsForUser = derived(ownManagerOrObserverRounds, (rounds) => (id) => (rounds === null || rounds === void 0 ? void 0 : rounds.filter((r) => r.employee === id && r.state === RoundState.Completed)) || null);
// Mutations
export async function add(data) {
    validate(data);
    return addDoc(collection, data);
}
export async function update(id, data) {
    validate(data);
    return updateDoc(doc(collection, id), data);
}
export async function remove(id) {
    return deleteDoc(doc(collection, id));
}
