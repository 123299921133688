<script lang="ts">
	import { _ } from 'svelte-i18n'
	import { OverflowMenu, OverflowMenuItem } from 'carbon-components-svelte'
	import UserAvatarFilled from 'carbon-icons-svelte/lib/UserAvatarFilled24'

	import { me, logout } from '../../../store/auth'
</script>

<OverflowMenu flipped size="sm" style="width: auto;">
	<div slot="menu">
		<UserAvatarFilled />
		{$me && $me.name}
	</div>

	<OverflowMenuItem href="/me">{$_('common.account')}</OverflowMenuItem>
	<OverflowMenuItem danger on:click={() => logout()}>{$_('common.logout')}</OverflowMenuItem>
</OverflowMenu>

<style>
	div {
		font-size: var(--cds-body-short-01-font-size, 0.875rem);
		color: var(--cds-text-01, #161616);
		padding-right: 2.5em;
		padding-left: 0.5em;
	}

	div > :global(svg) {
		position: absolute;
		top: 0.4em;
		right: 0.5em;
		height: 1.5em;
	}
</style>
