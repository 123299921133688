<script lang="ts">
	import { FeedbackRating, UserRole } from '@lovoo-dna/shared'
	import { DNA_VALUES } from '@lovoo-dna/shared'
	import { Button, Tile, Tag } from 'carbon-components-svelte'
	import { _ } from 'svelte-i18n'
	import Chat from 'carbon-icons-svelte/lib/Chat32'

	import { ownManagerOrObserverFeedbacksForRound } from '../../../../store/feedbacks'
	import { one, oneOwnManagerOrObserverRounds } from '../../../../store/rounds'
	import { decodeScore, getSmileyForValue } from '../../../../lib/dna'
	import { isOwnManagerForUser } from '../../../../store/groups'
	import { isAdmin, me } from '../../../../store/auth'

	import Avatar from '../../../../components/Avatar.svelte'
	import BackButton from '../../../../components/BackButton.svelte'
	import DnaWithIcon from '../../../../components/DNAWithIcon.svelte'
	import RatingComment from '../../components/RatingComment.svelte'
	import RoundManager from './RoundManager.svelte'
	import { getUserName } from '../../../../store/users'

	export let id: string

	type RoundSnippet = Array<{ from: string; feedback: string; anonymous: boolean } & FeedbackRating>
	const feedbacks = ownManagerOrObserverFeedbacksForRound(id)
	let byDNA: Record<string, RoundSnippet> | null = null
	$: if ($feedbacks) {
		const tmp = Object.fromEntries(DNA_VALUES.map((value) => [value, [] as RoundSnippet]))
		for (const feedback of $feedbacks) {
			if (!feedback.result) continue
			for (const type of DNA_VALUES) {
				const ratings = feedback.result.ratings[type as keyof typeof feedback.result.ratings]
				if (ratings && typeof ratings !== 'number')
					tmp[type].push({
						...ratings,
						from: feedback.from,
						feedback: feedback.id,
						anonymous: feedback.result.anonymous,
					})
			}
		}
		byDNA = tmp
	}
	$: round = $me && $me.role === UserRole.Admin ? $one(id) : $oneOwnManagerOrObserverRounds(id)
	$: isManager = !!round && $isOwnManagerForUser(round.employee)
</script>

<section>
	<BackButton />

	{#if round}
		<div class="mv5">
			<Avatar id={round.employee} display />
		</div>
	{/if}

	{#if isManager && round}
		<RoundManager {round} />
	{/if}

	{#each DNA_VALUES as type}
		{#if byDNA && byDNA[type].length}
			<Tile class="mv4">
				<DnaWithIcon class="mb4" {type} />
				{#each byDNA[type] as item, i}
					{#if i !== 0}
						<hr class="mv3" />
					{/if}
					<div class="flex rating">
						<div class="smiley mr4 tc">
							<div>
								{getSmileyForValue(item.rating)}
							</div>
							<div class="code f3 mt3">{decodeScore(item.rating)}</div>
						</div>
						<div class="flex-grow">
							<div class="mb2 flex items-center">
								{#if $isAdmin || !item.anonymous}
									<b class="mr2">
										{$getUserName(item.from)}
									</b>
								{/if}
								{#if item.anonymous}
									<Tag size="sm">{$_('descriptions.givenAnonymous')}</Tag>
								{/if}
							</div>
							<RatingComment rating={item} />
						</div>
						{#if isManager}
							<div class="ml4">
								<Button
									href="/chat/{item.feedback}"
									icon={Chat}
									iconDescription={$_('actions.startConversation')}
									size="small"
								/>
							</div>
						{/if}
					</div>
				{/each}
			</Tile>
		{/if}
	{/each}
	<BackButton />
</section>

<style>
	.smiley {
		font-size: 2rem;
		width: 3rem;
	}

	.rating {
		padding: 0 0.625rem;
	}
</style>
