<script lang="ts">
	import { _ } from 'svelte-i18n'
	import { Button } from 'carbon-components-svelte'
	import Left from 'carbon-icons-svelte/lib/ArrowLeft32'

	function back() {
		window.history.back()
	}
</script>

<span>
	<Button icon={Left} kind="ghost" size="small" on:click={back}>{$_('actions.back')}</Button>
</span>

<style>
	span :global(button) {
		height: 2rem;
		color: var(--cds-text-01, #161616);
		border-color: var(--cds-text-01, #161616);
	}
</style>
