<script lang="ts">
	import { _ } from 'svelte-i18n'

	import type { DNAWithSummary } from '../../../../lib/dna'
	import type { PlotData, PlotOptions } from './Plot.svelte'
	import Plot from './Plot.svelte'
	import DnaWithIcon from '../../../../components/DNAWithIcon.svelte'
	import ShadowBox from '../../../../components/ShadowBox.svelte'

	export let data: PlotData
	export let options: PlotOptions | undefined = undefined
	export let type: typeof DNAWithSummary[number]
</script>

<ShadowBox {...$$restProps}>
	<Plot {data} {options}>
		<DnaWithIcon {type}>
			<slot />
		</DnaWithIcon>
	</Plot>
</ShadowBox>
