<div {...$$restProps}>
	<slot />
</div>

<style>
	div {
		box-shadow: 0 -0.65rem 2rem #00000005;
		padding: 1em;
	}

	:global(html[theme='g90']) div,
	:global(html[theme='g100']) div {
		box-shadow: 0 -0.65rem 2rem #ffffff05;
	}
</style>
