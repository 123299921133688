<script lang="ts">
	import { Tabs, TabContent } from 'carbon-components-svelte'
	import { _ } from 'svelte-i18n'

	import TabWithoutLink from '../../components/TabWithoutLink.svelte'
	import Users from './views/Users.svelte'
	import Groups from './views/Groups.svelte'
	import Stats from './views/Stats.svelte'
</script>

<h1>{$_('common.admin')}</h1>
<br />
<br />

<Tabs>
	<TabWithoutLink href="" label={$_('common.users')} />
	<TabWithoutLink href="" label={$_('common.groups')} />
	<TabWithoutLink href="" label={$_('common.statistics')} />

	<div slot="content">
		<TabContent>
			<Users />
		</TabContent>
		<TabContent>
			<Groups />
		</TabContent>
		<TabContent>
			<Stats />
		</TabContent>
	</div>
</Tabs>

<style>
	div > :global(div) {
		margin-top: 2em;
		padding: 0;
	}
</style>
