import { DNA_VALUES_V4, summaryAverage } from '@lovoo-dna/shared';
import { mean } from 'lodash';
import dayjs from 'dayjs';
export function aggregateUserRounds(rounds) {
    // Only consider feedbacks within the last 1.5 years
    const maxAge = dayjs().subtract(1.5, 'years').unix();
    const roundsToInclude = rounds.filter((round) => round.created.seconds >= maxAge && round.summary);
    // Select by round date and calculate averages
    let total = []; // For global average
    const data = roundsToInclude
        .map((round) => Object.entries(round.summary)
        .filter(([key]) => key !== 'version')
        .map(([key, value]) => {
        total.push(value);
        return { group: key, value: decodeScore(value, true), date: round.created.toDate() };
    }))
        .flat(2);
    // DNA Average
    roundsToInclude.map((round) => {
        data.push({
            group: DNA_SUMMARY,
            value: decodeScore(summaryAverage(round.summary), true),
            date: round.created.toDate(),
        });
    });
    // Global average
    const dna = decodeScore(mean(total), true);
    return { data, dna };
}
export const StepParams = {
    minChars: 30,
    minRating: 0.9,
};
export const count = (s) => s.replace(/[^\w]/g, '').length;
/**
 *
 * @param rating
 * @returns an array of [valid, chars]
 * valid: whether the step is valid
 * chars, how many characters of feedback the rating has in total
 */
export function isStepValid(rating) {
    if (!rating)
        return [false, 0];
    const chars = count(rating.pros) + count(rating.cons);
    return [rating.rating >= StepParams.minRating || chars >= StepParams.minChars, chars];
}
export function getSmileyForValue(value) {
    if (value > 0.8)
        return '😃';
    if (value > 0.6)
        return '🙂';
    if (value > 0.4)
        return '🤨';
    if (value > 0.2)
        return '😕';
    return '🙁';
}
export function decodeScore(value, numeric = false) {
    const converted = value * 100;
    return numeric ? converted : converted.toFixed(0);
}
export const DNA_SUMMARY = 'dna';
// export type DNA_SUMMARY = typeof DNA_VALUES_V4[number] | 'dna'
export const DNAWithSummary = [DNA_SUMMARY, ...DNA_VALUES_V4];
