<script lang="ts">
	import { Tag } from 'carbon-components-svelte'

	export let weeks: number

	let bg = ''
	let color = ''
	$: {
		if (weeks > 13) {
			bg = '--cds-support-01'
			color = '--cds-inverse-01'
		} else if (weeks > 8) {
			bg = '--cds-support-03'
			color = '--foo-bar, #000000'
		} else {
			bg = '--cds-tag-background-gray'
			color = '--cds-tag-color-gray'
		}
	}
</script>

<Tag size="sm" style="background-color: var({bg}); color: var({color});">{weeks} w.</Tag>
