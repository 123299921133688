<script lang="ts" context="module">
	export type Changelog = {
		version: string
		changes: string[]
		description: string
	}[]
</script>

<script lang="ts">
	import { Button, Tile } from 'carbon-components-svelte'
	import { onMount } from 'svelte'
	import { _ } from 'svelte-i18n'
	import semver from 'semver'

	// @ts-ignore
	import { version as latest } from '../../../package.json'
	import { updateUser } from '../firebase/functions'
	import { me } from '../store/auth'
	import { notify } from './Notifications.svelte'

	const changelog: Changelog = [
		{
			version: '4.0.0',
			description: `🎉 It's finally here! A new version, new look and features 🥳`,
			changes: [
				'Redesign: Cleaner & System theme, or choose a permanent dark mode 😉',
				'Autosaving: Start a feedback and resume later on a different device.',
				'Slack notifications: Choose between E-Mail and Slack',
				'3rd party authentication cookies no longer required.',
			],
		},
	].sort((a, b) => semver.compare(b.version, a.version))

	let hide = true
	onMount(() => {
		if ($me) {
			const saved = $me.changelog
			const highest = changelog[0].version
			if (!saved || semver.lt(saved, highest)) hide = false
		}
	})

	function dismiss() {
		hide = true
		updateUser({ changelog: latest }).then(() => {
			notify({ title: 'Saved', kind: 'info' })
		})
	}
</script>

<div class:hide>
	<div class="curtain" />
	<div class="content">
		<Tile>
			<h1>Changelog</h1>
			<div class="list mv4">
				{#each changelog as version, i}
					{#if i !== 0}
						<hr class="mv4" />
					{/if}
					<h2 class="ma0">{version.version}</h2>
					<p class="mv3">{version.description}</p>
					<ul>
						{#each version.changes as change}
							<li class="mt2">▶ {change}</li>
						{/each}
					</ul>
				{/each}
			</div>
			<Button on:click={dismiss}>{$_('actions.continue')}</Button>
		</Tile>
	</div>
</div>

<style>
	div.hide {
		display: none;
	}

	.curtain {
		position: fixed;
		top: 0;
		left: 0;
		height: 100vh;
		width: 100vw;
		z-index: 9000;
		background-color: var(--cds-ui-background, #ffffff);
		opacity: 0.95;
		backdrop-filter: blur(4px);
		-webkit-backdrop-filter: blur(4px);
	}

	.content {
		position: fixed;
		z-index: 90001;
		max-width: var(--max-width-sm);
		padding: 1rem;
		width: 100%;
		height: 100vh;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
	}

	.list {
		max-height: calc(100vh - 14rem);
		overflow: auto;
		height: 100%;
	}
</style>
