<script lang="ts">
	import { Button } from 'carbon-components-svelte'

	import { logout } from '../store/auth'
</script>

<div class="center tc">
	<h1>User does not exists or is not active.</h1>
	<p class="mb3">Contact Administration</p>
	<Button on:click={logout}>Logout</Button>
</div>
