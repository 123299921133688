<script lang="ts">
	import { DataTable, Button } from 'carbon-components-svelte'
	import { date, _ } from 'svelte-i18n'
	import Delete32 from 'carbon-icons-svelte/lib/Delete32'
	import Quadrant from 'carbon-icons-svelte/lib/QuadrantPlot32'

	import type { UserClient } from '../../../store/users'
	import { getRoundsForUser, remove } from '../../../store/rounds'
	import { DEV, normalizeDate } from '../../../lib/utils'
	import { createMockRound } from '../../../lib/mock'

	import CreateRoundForm from './CreateRoundForm.svelte'
	import Modal from '../../../components/Modal.svelte'
	import Actions from './Actions.svelte'
	import { notify } from '../../../components/Notifications.svelte'
	import Spinner from '../../../components/Spinner.svelte'
	import EmptyNotice from '../../../components/EmptyNotice.svelte'
	import RoundForm from './RoundForm.svelte'

	export let user: UserClient

	const headers = [
		{ key: 'date', value: $_('common.created') },
		{ key: 'state', value: $_('common.state') },
		{ key: 'actions', empty: true },
	]

	$: rounds = $getRoundsForUser(user.id)
	$: rows = rounds?.map((round) => ({
		...round,
		date: $date(normalizeDate(round.created), { format: 'medium' }),
		state: $_('common.roundState.' + round.state),
	}))

	let createOpen = false

	let delOpen = false
	let delID: string | null = null
	function del(id: string) {
		delID = id
		delOpen = true
	}
	function delFN() {
		if (delID) remove(delID)
		delOpen = false
	}

	let editOpen = false
	let editID: string | null = null
	const edit = (id: string) => {
		editID = id
		editOpen = true
	}

	async function mock(id: string) {
		try {
			await createMockRound(id)
		} catch (e) {
			notify({ subtitle: e, title: 'Error' })
		}
	}
</script>

<Modal bind:open={createOpen} title={$_('common.round')} let:done>
	<CreateRoundForm id={user.id} {done} />
</Modal>

<Modal bind:open={delOpen} title="{$_('actions.deleteRound')}?">
	<p>{$_('descriptions.permanentActionWarning')}</p>
	<br />
	<Button kind="danger" icon={Delete32} on:click={delFN}>
		{$_('actions.delete')}
	</Button>
</Modal>

<Modal bind:open={editOpen} title={$_('actions.editRound')} size="lg" let:done let:setBlocked>
	{#if editID}
		<RoundForm id={editID} {done} {setBlocked} />
	{/if}
</Modal>

<div class="wrapper">
	{#if rounds === null}
		<Spinner />
	{:else}
		<div class="flex mb3">
			<h2 class="w-100">{$_('common.rounds')}</h2>
			{#if DEV}
				<Button size="small" kind="secondary" on:click={() => mock(user.id)}>{$_('actions.createMock')}</Button>
			{/if}
			<Button size="small" on:click={() => (createOpen = true)}>{$_('actions.create')}</Button>
		</div>
		{#if rows && rows.length}
			<DataTable {headers} {rows}>
				<span slot="cell" let:cell let:row>
					{#if cell.key === 'actions'}
						<Actions data={row.id} {del} {edit} />
					{:else}{cell.value}{/if}
				</span>
			</DataTable>
		{:else}
			<EmptyNotice icon={Quadrant} message={$_('descriptions.noRounds')} />
		{/if}
	{/if}
</div>

<style>
	.wrapper {
		margin-left: calc(-1 * var(--cds-spacing-09));
		background: var(--cds-ui-background);
		padding: var(--cds-spacing-05);
		margin-right: calc(-1 * var(--cds-spacing-05));
		padding-left: var(--cds-spacing-06);
		padding-right: var(--cds-spacing-05);
	}

	h2 {
		font-size: 1.5em;
		margin-left: var(--cds-spacing-05, 1rem);
	}

	.wrapper :global(th) {
		padding: 0 !important;
	}

	.wrapper :global(td),
	.wrapper :global(.bx--table-header-label) {
		padding-left: var(--cds-spacing-05, 1rem) !important;
	}

	.wrapper :global(.actions) {
		margin-right: calc(-1 * var(--cds-spacing-05, 1rem));
	}
</style>
