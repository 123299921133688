<script lang="ts" context="module">
	export type Action = {
		icon: typeof CarbonIcon
		text: string
		action: Function
		kind: ButtonProps['kind']
	}
</script>

<script lang="ts">
	import type { ButtonProps } from 'carbon-components-svelte/types/Button/Button.svelte'
	import type { CarbonIcon } from 'carbon-icons-svelte'
	import { _ } from 'svelte-i18n'
	import { Button } from 'carbon-components-svelte'
	import Delete32 from 'carbon-icons-svelte/lib/Delete32'
	import SettingsAdjust32 from 'carbon-icons-svelte/lib/SettingsAdjust32'

	export let data: any
	export let extra: Action[] = []
	export let edit: Function | null = null
	export let del: Function | null = null

	let all: Action[] = []

	$: {
		all.push(...extra)
		if (edit)
			all.push({
				icon: SettingsAdjust32,
				text: $_('actions.edit'),
				action: edit,
				kind: 'secondary',
			})
		if (del)
			all.push({
				icon: Delete32,
				text: $_('actions.delete'),
				action: del,
				kind: 'danger',
			})
	}
</script>

<div class="flex justify-end">
	{#each all as { icon, text, kind, action }}
		<Button
			{kind}
			{icon}
			size="small"
			on:click={(e) => {
				e.stopPropagation()
				action(data)
			}}
			tooltipPosition="bottom"
			tooltipAlignment="center"
			iconDescription={text}
		/>
	{/each}
</div>
