<script lang="ts" context="module">
	export type Action = {
		icon: typeof CarbonIcon
		text: string
		action: Function
		kind: string
	}
</script>

<script lang="ts">
	import { _ } from 'svelte-i18n'

	import type { CarbonIcon } from 'carbon-icons-svelte'

	export let value: boolean
	export let yes: typeof CarbonIcon
	export let no: typeof CarbonIcon
</script>

<svelte:component this={value ? yes : no} />

<style>
</style>
