<script lang="ts">
	import { InlineNotification } from 'carbon-components-svelte'
	import { _ } from 'svelte-i18n'

	export let error: string | Error | null = null
	const i18n = '🌐'
	let title: string = ''

	$: if (error) {
		if (error instanceof Error && error.message.startsWith(i18n)) {
			title = $_('errors.' + error.message.slice(i18n.length))
		} else {
			title = error.toString()
		}
	}
</script>

{#if error}
	<InlineNotification kind="error" hideCloseButton {title} />
{/if}
