<script lang="ts">
	import { FeedbackState } from '@lovoo-dna/shared'
	import { createEventDispatcher } from 'svelte'
	import { _ } from 'svelte-i18n'
	import { Button, TextInput } from 'carbon-components-svelte'
	import Delete32 from 'carbon-icons-svelte/lib/Delete32'

	import { formatDate } from '../../../lib/utils'
	import type { FeedbackClient } from '../../../store/feedbacks'
	import { getUserName } from '../../../store/users'
	import DatePicker from '../../../components/DatePicker.svelte'

	function isNew(feedback: FeedbackClient | string): feedback is string {
		return typeof feedback === 'string'
	}

	function getDate(feedback: FeedbackClient | string): Date {
		return isNew(feedback) ? new Date() : feedback.deadline.toDate()
	}
	function getCompleted(feedback: FeedbackClient | string) {
		return isNew(feedback) ? false : feedback.state === FeedbackState.Completed
	}
	function getSubmitted(feedback: FeedbackClient | string) {
		return isNew(feedback) ? false : feedback.result !== null
	}

	const dispatch = createEventDispatcher()

	export let feedback: FeedbackClient | string
	export let deleted = false
	export let remove: (e: MouseEvent) => void

	const submitted = getSubmitted(feedback)
	const completed = getCompleted(feedback)
	let deadline = getDate(feedback)

	function check(e: any) {
		if (isNew(feedback)) return
		const date = e.detail.selectedDates[0]
		dispatch('date', date)
	}
</script>

<fieldset disabled={deleted}>
	<div class="flex items-end">
		<div class="flex-grow">
			<TextInput
				size="sm"
				labelText={$_('common.user')}
				value={$getUserName(isNew(feedback) ? feedback : feedback.from)}
				readonly
			/>
		</div>
		{#if !isNew(feedback)}
			<div class="ml1">
				<TextInput
					size="sm"
					labelText={$_('descriptions.selectedBy')}
					value={$getUserName(feedback.selectedBy)}
					readonly
				/>
			</div>
			<div class="ml1 date">
				{#if completed && submitted}
					<TextInput size="sm" labelText={$_('common.deadline')} value={formatDate(deadline)} readonly />
				{:else}
					<DatePicker size="sm" label={$_('common.deadline')} bind:date={deadline} on:change={check} />
				{/if}
			</div>
			<div class="ml1 submitted" class:error={completed && !submitted}>
				<TextInput
					size="sm"
					labelText={$_('common.submitted')}
					value={$_(submitted ? 'common.yes' : 'common.no')}
					readonly
				/>
			</div>
		{/if}
		<div class="ml1">
			<Button
				disabled={completed}
				icon={Delete32}
				kind="danger"
				size="small"
				iconDescription={$_('actions.delete')}
				on:click={remove}
			/>
		</div>
	</div>
</fieldset>

<style>
	.submitted {
		width: 5rem;
	}

	div.error :global(input) {
		background-color: var(--cds-text-error);
		font-weight: bold;
		color: var(--cds-text-04);
	}
	div.date,
	div.date :global(.bx--date-picker.bx--date-picker--single .bx--date-picker__input) {
		width: 12rem;
	}
</style>
