<script lang="ts">
	import { MultiSelect, Tag } from 'carbon-components-svelte'

	import { all, getUserName } from '../store/users'

	export let selected: string[] = []
	export let label: string = ''

	let items = $all?.map((user) => ({ id: user.id, text: $getUserName(user.id) })) || []
</script>

{#if items}
	<MultiSelect light filterable titleText={label} bind:selectedIds={selected} {items} />
	<div class="mt1">
		{#each selected as id (id)}
			<Tag filter on:click={() => (selected = selected.filter((s) => s !== id))}>
				{items.find((i) => i.id === id)?.text}
			</Tag>
		{/each}
	</div>
{/if}
