import 'carbon-components-svelte/css/all.css';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(tz);
dayjs.tz.guess();
import './locale';
import { init } from './firebase';
import App from './App.svelte';
init.then(() => {
    new App({ target: document.body });
});
