<script lang="ts">
	import { Route } from 'tinro'

	import Account from './views/Account.svelte'
	import Changelog from '../../components/Changelog.svelte'
	import Chat from './views/chat/Chat.svelte'
	import Chats from './views/chat/Chats.svelte'
	import Dashboard from './views/Dashboard.svelte'
	import Dna from './views/dna/DNA.svelte'
	import Feedbacks from './views/feedbacks/Feedbacks.svelte'
	import Round from './views/round/Round.svelte'
	import Submit from './views/submit/Submit.svelte'
	import Teams from './views/teams/Teams.svelte'
</script>

<div>
	<Changelog />
	<Route path="/">
		<Dashboard />
	</Route>
	<Route path="/me">
		<Account />
	</Route>
	<Route path="/teams">
		<Teams />
	</Route>
	<Route path="/feedbacks">
		<Feedbacks />
	</Route>
	<Route path="/round/:id" let:meta>
		<Round id={meta.params.id} />
	</Route>
	<Route path="/submit/:id" let:meta>
		<Submit id={meta.params.id} />
	</Route>
	<Route path="/dna/:id" let:meta>
		<Dna id={meta.params.id} />
	</Route>
	<Route path="/chat">
		<Chats />
	</Route>
	<Route path="/chat/:id" let:meta>
		<Chat id={meta.params.id} />
	</Route>
</div>

<style>
	div {
		width: 100%;
		max-width: var(--max-width);
		margin: auto;
	}
</style>
