import { derived } from 'svelte/store';
import { FeedbackFrequency, FeedbackState, } from '@lovoo-dna/shared';
import lodash from 'lodash';
import { query, where, orderBy } from 'firebase/firestore';
import { getCollection, Timestamp } from '../firebase';
import { createStore } from '../firebase/adapter';
import { me } from './auth';
import { ownManagerOrObserverRounds } from './rounds';
import { universalInClause } from './utils';
export const collection = getCollection('feedbacks');
const collectionSorted = query(collection, orderBy('created', 'desc'));
function emptyRating() {
    return { rating: 0.5, pros: '', cons: '' };
}
export function emptyResult(version = 4) {
    const ratings = version === 4
        ? {
            version: 4,
            fireball: emptyRating(),
            competence: emptyRating(),
            innovator: emptyRating(),
            teamplayer: emptyRating(),
            accountability: emptyRating(),
        }
        : {
            version: 3,
            passion: emptyRating(),
            competence: emptyRating(),
            goalgetter: emptyRating(),
            teamplayer: emptyRating(),
            accountability: emptyRating(),
        };
    return {
        submitted: Timestamp.now(),
        note: '',
        frequency: FeedbackFrequency.Daily,
        anonymous: true,
        ratings,
    };
}
// Queries & Derived
export const all = createStore(collectionSorted);
export const getFeedbacksForRound = derived(all, ($all) => (id) => ($all === null || $all === void 0 ? void 0 : $all.filter((fb) => fb.round === id)) || null);
export const ownFeedbacks = derived(me, ($me, set) => {
    if ($me) {
        const q = query(collection, where('result.anonymous', '==', false), where('to', '==', $me.id));
        createStore(q).subscribe(set);
    }
}, null);
export const ownFeedbacksForRound = derived(ownFeedbacks, (feedbacks) => (id) => (feedbacks === null || feedbacks === void 0 ? void 0 : feedbacks.filter((fb) => fb.round === id)) || null);
export const ownGivenFeedbacks = derived(me, ($me, set) => {
    if ($me)
        createStore(query(collectionSorted, where('from', '==', $me.id))).subscribe(set);
}, null);
export const ownGivenCompletedFeedbacks = derived(ownGivenFeedbacks, ($fb) => ($fb === null || $fb === void 0 ? void 0 : $fb.filter((fb) => fb.state === FeedbackState.Completed)) || null);
export const ownOpenGivenFeedbacks = derived(ownGivenFeedbacks, ($feedbacks) => ($feedbacks === null || $feedbacks === void 0 ? void 0 : $feedbacks.filter((fb) => fb.state === FeedbackState.Active)) || null);
export const ownOneFeedback = derived(ownGivenFeedbacks, (fbs) => (id) => (fbs === null || fbs === void 0 ? void 0 : fbs.find((fb) => fb.id === id)) || null);
export const ownManagerOrObserverFeedbacksForRound = (id) => {
    const q = query(collectionSorted, where('round', '==', id));
    return createStore(q);
};
export const ownManagerOrObserverCompletedFeedbacks = derived(ownManagerOrObserverRounds, (rounds, set) => {
    if (!rounds) {
        set(null);
    }
    else {
        const roundIds = rounds.map((r) => r.id);
        universalInClause(collectionSorted, 'round', roundIds).subscribe(set);
    }
});
export const ownFeedbacksWithMessages = derived([ownGivenCompletedFeedbacks, ownManagerOrObserverCompletedFeedbacks], ([own, managed], set) => {
    if (!own || !managed)
        set(null);
    else {
        const all = lodash.uniqBy([...own, ...managed], (x) => x.id);
        set(all.filter((fb) => fb.messages > 0));
    }
}, null);
