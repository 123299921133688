<script context="module" lang="ts">
	import type { ToastNotificationProps } from 'carbon-components-svelte/types/Notification/ToastNotification.svelte'
	import { writable } from 'svelte/store'

	type Notification = ToastNotificationProps & { rand: number }

	const notifications = writable([] as Notification[])

	export function notify(props: ToastNotificationProps) {
		const cleaned: Notification = { rand: Math.random(), caption: '', lowContrast: true, timeout: 5000, ...props }
		notifications.update((value) => [...value, cleaned])
	}
</script>

<script lang="ts">
	import { ToastNotification } from 'carbon-components-svelte'
</script>

<div>
	{#each $notifications as { rand, ...notification } (rand)}
		<ToastNotification {...notification} />
	{/each}
</div>

<style>
	div {
		position: fixed;
		top: 0;
		left: 0;
		padding: 1em;
		z-index: 2000;
	}
</style>
