<script lang="ts">
	import { _ } from 'svelte-i18n'
	import Icon from 'carbon-icons-svelte/lib/FavoriteFilled32'

	import { ownOpenGivenFeedbacks } from '../../../../store/feedbacks'
	import { ownManagerSelectRounds, ownSelectRound } from '../../../../store/rounds'
	import OpenRounds from '../../components/OpenRounds.svelte'
	import OpenFeedbacks from '../me/OpenFeedbacks.svelte'
	import Spinner from '../../../../components/Spinner.svelte'
	import EmptyNotice from '../../../../components/EmptyNotice.svelte'

	let available: number | null = null

	$: {
		const manager = $ownManagerSelectRounds
		const select = $ownSelectRound
		const feedbacks = $ownOpenGivenFeedbacks
		if (manager && select && feedbacks) {
			available = manager.length + select.length + feedbacks.length
		}
	}
</script>

<section>
	{#if available === null}
		<Spinner />
	{:else if available === 0}
		<EmptyNotice icon={Icon} message={$_('descriptions.noActionRequired')} />
	{:else}
		<h2 class="mb3">{$_('common.actions')}</h2>
	{/if}
	<OpenRounds />
	<OpenRounds manager />
	<OpenFeedbacks />
</section>
