<script lang="ts">
	import { _ } from 'svelte-i18n'
	import User from 'carbon-icons-svelte/lib/User24'
	import { Button, Form, Tile } from 'carbon-components-svelte'

	import type { UserClient } from '../../../store/users'
	import type { FeedbackStore } from '../../../store/feedbacks'
	import { addPeersToRound, getLastSelected } from '../../../firebase/functions'
	import { ownSelectRound, ownManagerSelectRounds } from '../../../store/rounds'
	import { getUserName } from '../../../store/users'
	import { ownManagerOrObserverFeedbacksForRound } from '../../../store/feedbacks'
	import { formatDate } from '../../../lib/utils'

	import Avatar from '../../../components/Avatar.svelte'
	import TilePrompt from '../../../components/TilePrompt.svelte'
	import Modal from '../../../components/Modal.svelte'
	import SingleUserSelect from '../../../components/SingleUserSelect.svelte'
	import InlineError from '../../../components/InlineError.svelte'
	import Spinner from '../../../components/Spinner.svelte'

	export let manager = false

	const defaultSelected = [undefined, undefined, undefined]

	let error: Error | null = null
	let loading = false
	let open = false
	let round: string | null = null
	let selected: Array<undefined | string> = defaultSelected
	let lastPeers: string[] | null = null
	let feedbacks: FeedbackStore = null

	$: rounds = manager ? $ownManagerSelectRounds : $ownSelectRound
	$: alreadyChosenIds = feedbacks ? feedbacks.map((fb) => fb.from) : []
	$: filter = (user: UserClient) => {
		return user.open < 4 && !alreadyChosenIds.includes(user.id)
	}

	function openFN(id: string) {
		return async () => {
			round = id
			selected = [undefined, undefined, undefined]
			if (manager) {
				ownManagerOrObserverFeedbacksForRound(id).subscribe((fbs) => (feedbacks = fbs))
			}
			lastPeers = null
			getLastSelected({ round }).then(({ peers }) => (lastPeers = peers))
			error = null
			open = true
		}
	}

	$: if (!open) {
		round = null
		selected = defaultSelected
		lastPeers = null
		feedbacks = null
	}

	async function submit() {
		try {
			loading = true
			error = null
			if (!round) throw new Error('No round selected')
			const cleaned = selected.filter((uid) => !!uid) as string[]
			await addPeersToRound({ id: round, peers: cleaned })
			open = false
		} catch (e) {
			error = e
		} finally {
			loading = false
		}
	}
</script>

<Modal bind:open title={$_('actions.select')}>
	<Form>
		<Tile light class="mb3">
			{#if Array.isArray(lastPeers)}
				<div>
					<b>{$_('descriptions.peersChosenLastTime')}:</b>
					{#if lastPeers.length}
						<div class="avatars">
							{#each lastPeers as peer}
								<Avatar id={peer} small />
							{/each}
						</div>
					{:else}
						{$_('common.empty')}
					{/if}
				</div>
			{:else}
				<Spinner small />
			{/if}
			{#if manager}
				<div class="mt3">
					<b>{$_('descriptions.alreadyChosen')}</b>
					{#if Array.isArray(feedbacks)}
						<div class="avatars">
							{#each feedbacks as feedback}
								<Avatar id={feedback.from} small />
							{/each}
						</div>
					{:else}
						<Spinner small />
					{/if}
				</div>
			{/if}
		</Tile>

		<fieldset disabled={loading}>
			<SingleUserSelect direction="top" label={$_('common.peer')} bind:selected={selected[0]} {filter} />
			<br />
			<SingleUserSelect direction="top" label={$_('common.peer')} bind:selected={selected[1]} {filter} />
			<br />
			<SingleUserSelect direction="top" label={$_('common.peer')} bind:selected={selected[2]} {filter} />
			<br />
			<br />
			<Button type="button" on:click={submit}>{$_('actions.select')}</Button>
		</fieldset>
		<InlineError {error} />
	</Form>
</Modal>

{#if rounds && rounds.length}
	{#each rounds as { id, deadline, employee } (id)}
		<TilePrompt icon={User} button={$_('actions.select')} on:click={openFN(id)}>
			{#if deadline}
				{@html manager
					? $_('actions.selectPeersManager', {
							values: { date: formatDate(deadline.toDate()), for: $getUserName(employee) },
					  })
					: $_('actions.selectPeersEmployee', {
							values: { date: formatDate(deadline.toDate()) },
					  })}
			{/if}
		</TilePrompt>
	{/each}
{/if}

<style>
	.avatars {
		margin: 0 -0.5rem;
	}
</style>
