<script lang="ts">
	import { Form, Button, TextInput } from 'carbon-components-svelte'
	import Save20 from 'carbon-icons-svelte/lib/Save20'
	import { get } from 'svelte/store'
	import { _ } from 'svelte-i18n'
	import { cloneDeep, isEqual } from 'lodash'

	import InlineError from '../../../components/InlineError.svelte'
	import SingleUserSelect from '../../../components/SingleUserSelect.svelte'
	import UserSelect from '../../../components/UserSelect.svelte'
	import IdTag from '../../../components/IDTag.svelte'

	import { add, empty, one, update } from '../../../store/groups'

	export let id: string | null = null
	export let done: Function
	export let setBlocked: Function

	let error: Error | null = null
	const original = id ? get(one)(id) : empty()
	const group = cloneDeep(original)
	$: setBlocked(!isEqual(original, group))

	async function submit(e: Event) {
		e.preventDefault()
		try {
			error = null
			if (!group) throw new Error()
			if (id) {
				await update(id, group)
			} else {
				await add(group)
			}
			done()
		} catch (e) {
			console.error(e)
			error = e
		}
	}
</script>

{#if group}
	<Form on:submit={submit}>
		<TextInput bind:value={group.name} label={$_('common.name')} />
		<br />
		<SingleUserSelect bind:selected={group.manager} label={$_('common.manager')} />
		<br />
		<UserSelect bind:selected={group.observers} label={$_('common.observers')} />
		<br />
		<UserSelect bind:selected={group.members} label={$_('common.members')} />
		<br />
		<br />
		<Button type="submit" icon={Save20}>{$_(id ? 'actions.update' : 'actions.create')}</Button>
		<InlineError {error} />
		<IdTag {id} />
	</Form>
{/if}
