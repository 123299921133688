<script lang="ts">
	import { Route } from 'tinro'

	import { UserRole } from '@lovoo-dna/shared'
	import { auth, me } from './store/auth'

	import Login from './components/Login.svelte'
	import Nav from './views/nav/Nav.svelte'
	import Admin from './views/admin/Admin.svelte'
	import Tool from './views/tool/Tool.svelte'
	import UserMissing from './components/UserMissing.svelte'
	import Fallback from './components/Fallback.svelte'
	import Notifications from './components/Notifications.svelte'
	import Footer from './components/Footer.svelte'
	import Spinner from './components/Spinner.svelte'
	import { DEV } from './lib/utils'
	import Dev from './components/Dev.svelte'
</script>

{#if $auth !== undefined}
	{#if $auth === null}
		<Login />
	{:else}
		<main class="pa3 pt6 pb4">
			{#if $me !== null}
				{#if $me === false}
					<UserMissing />
				{:else}
					<Route firstmatch>
						<Nav />
						<Notifications />
						<Route path="/admin/*">
							{#if $me?.role === UserRole.Admin}
								<Admin />
							{:else}
								<Fallback />
							{/if}
						</Route>
						<Tool />
						<Fallback />
					</Route>
				{/if}
			{:else}
				<Spinner />
			{/if}
		</main>
		<Footer />
	{/if}
{/if}

{#if DEV}
	<Dev />
{/if}

<style>
	main {
		width: 100%;
		position: relative;
		min-height: calc(100vh - 4rem);
	}
</style>
