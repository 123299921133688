import { GroupValidator } from '@lovoo-dna/shared';
import { cloneDeep } from 'lodash';
import { derived } from 'svelte/store';
import { get } from 'svelte/store';
import { query, addDoc, deleteDoc, orderBy, doc, updateDoc } from 'firebase/firestore';
import { getCollection } from '../firebase';
import { createStore } from '../firebase/adapter';
import { genValidator } from '../lib/utils';
import { all as allUsers } from './users';
import { me } from './auth';
const collection = getCollection('groups');
export function empty() {
    return {
        name: '',
        manager: '',
        observers: [],
        members: [],
    };
}
const validate = genValidator(GroupValidator, async (data, id) => {
    var _a;
    const members = [data.manager, ...data.members, ...data.observers];
    const unique = new Set(members);
    if (members.length != unique.size)
        throw new Error('🌐notManagerObserverMemberDuplicate');
    const groups = get(all);
    if (!groups)
        throw new Error('nope');
    for (const group of groups) {
        if (group.id === id)
            continue;
        const duplicate = group.members.find((r) => data.members.includes(r));
        if (duplicate) {
            const users = get(allUsers);
            throw new Error(`${(_a = users === null || users === void 0 ? void 0 : users.find((u) => u.id === duplicate)) === null || _a === void 0 ? void 0 : _a.name} already in ${group.name}`);
        }
    }
});
// QUERIES
export const all = createStore(query(collection, orderBy('name')));
export const one = derived(all, ($all) => (id) => $all === null || $all === void 0 ? void 0 : $all.find((u) => u.id === id));
export const groupOfUser = (id) => derived(all, ($all) => $all === null || $all === void 0 ? void 0 : $all.find((g) => g.members.includes(id) || null));
export const ownManagerGroups = derived([me, all], ([me, all]) => me && all ? all.filter((group) => group.manager === me.id) : null);
export const isOwnManagerForUser = derived(ownManagerGroups, ($groups) => (user) => { var _a; return (_a = $groups === null || $groups === void 0 ? void 0 : $groups.some((g) => g.members.includes(user))) !== null && _a !== void 0 ? _a : null; });
export const ownObserverGroups = derived([me, all], ([me, all]) => me && all ? all.filter((group) => group.observers.includes(me.id)) : null);
export const ownManagerOrObserverGroups = derived([ownManagerGroups, ownObserverGroups], ([managed, observer]) => (managed && observer ? [...managed, ...observer] : null));
// MUTATIONS
export async function add(data) {
    await validate(data);
    return addDoc(collection, data);
}
export async function update(id, data) {
    const cloned = cloneDeep(data);
    // @ts-ignore
    delete cloned['id'];
    await validate(cloned, id);
    return updateDoc(doc(collection, id), cloned);
}
export async function remove(id) {
    return deleteDoc(doc(collection, id));
}
