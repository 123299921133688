<script lang="ts">
	import Filled from 'carbon-icons-svelte/lib/StarFilled32'
	import Empty from 'carbon-icons-svelte/lib/Star32'

	export let value = 0.5
	export let min = 0
	export let max = 100
	export let step = 1

	let diff = max - min
	let internal = value * diff + min

	const encode = (x: number) => (internal = x * diff + min)
	const decode = (x: number) => (value = (x - min) / diff)
	$: decode(internal)
	$: encode(value)
</script>

<div class="flex flex-nowrap items-center">
	<Empty />
	<div class="wrapper">
		<input type="range" {min} {max} {step} bind:value={internal} on:change />
		<div class="seeker">
			<span style="left: {value * 100}%;">{internal.toFixed(0)}</span>
		</div>
	</div>
	<Filled />
</div>

<style>
	div :global(svg) {
		width: 1.5em;
		height: 1.5em;
	}

	.wrapper {
		position: relative;
		margin: 0 2rem;
		width: 100%;
		top: 0.125rem;
		height: 3rem;
	}

	.wrapper * {
		transition: all ease-in-out 150ms;
	}

	.seeker {
		position: absolute;
		top: 2.5rem;
		padding: 0 0.9rem;
		width: 100%;
		pointer-events: none;
	}

	.wrapper:hover .seeker {
		padding: 0 1.5rem;
	}

	.wrapper:hover .seeker span {
		transform: scale(2) translateX(-25%) translateY(0.5rem);
	}

	.seeker span {
		position: relative;
		top: 0;
		left: 0;
		text-align: center;
		display: block;
		transform: translateX(-50%);
		font-weight: monospace;
		transition-timing-function: ease;
	}

	input {
		-webkit-appearance: none;
		appearance: none;

		width: 100%;
		height: 0.25rem;
		background: var(--cds-field-01, #f4f4f4);
		outline: none;
		margin: 1.125rem 0;
		cursor: pointer;
		padding: 0;
		border-radius: 0;
	}

	.wrapper:hover input {
		height: 2.5rem;
		margin: 0;
		padding: 0 0.5rem;
		border-radius: 2em;
	}

	input::-moz-range-thumb {
		appearance: none;
		width: 1.75rem;
		height: 1.75rem;
		background-color: var(--cds-interactive-01, #0f62fe);
		border-radius: 100%;
		border: none;
	}
	input::-webkit-slider-thumb {
		-webkit-appearance: none;
		appearance: none;
		width: 1.75rem;
		height: 1.75rem;
		background-color: var(--cds-interactive-01, #0f62fe);
		border-radius: 100%;
		border: none;
	}
</style>
