<script lang="ts">
	import type { DataTableHeader, DataTableRow } from 'carbon-components-svelte/types/DataTable/DataTable.svelte'
	import { get } from 'svelte/store'
	import { router } from 'tinro'
	import { _ } from 'svelte-i18n'
	import dayjs from 'dayjs'
	import { DataTable, Toolbar, ToolbarContent, ToolbarSearch, Button, Dropdown } from 'carbon-components-svelte'
	import Delete32 from 'carbon-icons-svelte/lib/Delete32'
	import QuadrantPlot32 from 'carbon-icons-svelte/lib/QuadrantPlot32'
	import Archive32 from 'carbon-icons-svelte/lib/Archive32'
	import RadioButton from 'carbon-icons-svelte/lib/RadioButton24'
	import CheckmarkFilled from 'carbon-icons-svelte/lib/CheckmarkFilled24'
	import { calculateTrendFromRounds, Group, RoundState } from '@lovoo-dna/shared'
	import type { Trend as TTrend } from '@lovoo-dna/shared'

	import type { UserClient } from '../../../store/users'
	import { all, one, remove, update } from '../../../store/users'
	import { all as allGroups } from '../../../store/groups'
	import { getRoundsForUser } from '../../../store/rounds'
	import { FuzzySearch } from '../../../lib/utils'
	import { Timestamp } from '../../../firebase'

	import UserForm from '../components/UserForm.svelte'
	import ExpandedUser from '../components/ExpandedUser.svelte'
	import Modal from '../../../components/Modal.svelte'
	import type { Action } from '../components/Actions.svelte'
	import Actions from '../components/Actions.svelte'
	import IconSwitcher from '../../../components/IconSwitcher.svelte'
	import Trend from '../../../components/Trend.svelte'
	import WeeksCounter from '../components/WeeksCounter.svelte'

	const headers: DataTableHeader[] = [
		{ key: 'name', value: $_('common.name') },
		{ key: 'email', value: $_('common.email') },
		{ key: 'comment', value: $_('common.comment') },
		{ key: 'active', value: $_('common.active') },
		{ key: 'ongoing', value: $_('common.ongoing') },
		{ key: 'open', value: $_('common.open') },
		{ key: 'weeks', value: $_('common.weeks') },
		{
			key: 'trend',
			value: $_('common.trend'),
			sort(a: TTrend, b: TTrend) {
				return a.positive === b.positive ? (a.delta > b.delta ? 1 : -1) : a.positive ? 1 : -1
			},
		},
		{ key: 'actions', empty: true },
	]

	const extra: Action[] = [
		{
			icon: QuadrantPlot32,
			text: $_('actions.showDna'),
			action: (id: string) => {
				router.goto(`/dna/${id}`)
			},
			kind: 'primary',
		},
	]

	let needle: string = ''
	$: users = ($all || []).map((user) => {
		const rounds = $getRoundsForUser(user.id) || []
		const ongoing =
			rounds.some((round) =>
				[RoundState.Active, RoundState.SelectEmployee, RoundState.SelectManager].includes(round.state)
			) || false
		const weeks = rounds.length ? dayjs().diff(rounds[0].created.toDate(), 'weeks') : 0
		const trend = calculateTrendFromRounds(rounds)
		return {
			...user,
			ongoing,
			trend,
			weeks,
		}
	})
	$: fuse = new FuzzySearch(users, ['name', 'email', 'comment'])
	$: rows = fuse.search(needle)
	$: filtered = rows.filter((user) => !group || isUserInGroup(user.id, group))
	let expanded: string[] = []
	let groupId = 'all'
	$: group = $allGroups?.find((g) => g.id === groupId) ?? null

	function isUserInGroup(userId: string, group: Group): boolean {
		return group.manager === userId || group.members.includes(userId) || group.observers.includes(userId)
	}

	let editId: string | null = null
	let editOpen = false

	let delId: string | null = null
	let delUser: UserClient | null = null
	let delOpen = false
	async function delFN() {
		if (delId) await remove(delId)
		delOpen = false
	}
	async function scheduleFN() {
		if (delId) {
			if (delUser) {
				await update(delId, {
					...delUser,
					active: false,
					scheduledForDeletion: Timestamp.fromMillis(dayjs().add(4, 'months').endOf('day').valueOf()),
				})
				delOpen = false
			}
		}
	}

	function toggleRow(e: CustomEvent<DataTableRow>) {
		// @ts-ignore
		if (e.explicitOriginalTarget instanceof SVGElement) return // Ignore if clicking on the chevron
		const id = e.detail.id
		expanded = expanded.includes(id) ? expanded.filter((i) => i !== id) : [...expanded, id]
	}

	const del = (id: string) => {
		delId = id
		delUser = get(one)(delId) || null
		delOpen = true
	}
	const edit = (id: string) => {
		editId = id
		editOpen = true
	}

	// I will burn in hell
	const asUser = (user: any) => user as UserClient
</script>

<Modal
	bind:open={editOpen}
	title={editId ? $_('actions.updateUser') : $_('actions.createUser')}
	let:done
	let:setBlocked
>
	<UserForm id={editId} {done} {setBlocked} />
</Modal>

<Modal bind:open={delOpen} title="{$_('actions.deleteUser')}?">
	<p>{$_('descriptions.permanentActionWarning')}</p>
	<br />
	<Button kind="danger" icon={Delete32} on:click={delFN}>
		{$_('actions.delete')}
	</Button>
	{#if delUser && !delUser.scheduledForDeletion}
		<Button kind="danger" icon={Archive32} on:click={scheduleFN}>
			{$_('actions.scheduleForDeletion')}
		</Button>
	{/if}
</Modal>

<DataTable expandable zebra bind:expandedRowIds={expanded} sortable {headers} rows={filtered} on:click:row={toggleRow}>
	<Toolbar>
		<ToolbarContent>
			<ToolbarSearch bind:value={needle} />
			<Dropdown
				hideLabel
				type="inline"
				size="lg"
				bind:selectedId={groupId}
				items={[
					{ id: 'all', text: $_('common.all') },
					...($allGroups?.map((group) => ({
						id: group.id,
						text: group.name,
					})) ?? []),
				]}
			/>
			<Button
				on:click={() => {
					editId = null
					editOpen = true
				}}>{$_('actions.create')}</Button
			>
		</ToolbarContent>
	</Toolbar>

	<span slot="cell" let:cell let:row>
		{#if cell.key === 'actions'}
			<Actions data={row.id} {extra} {del} {edit} />
		{:else if cell.key === 'weeks'}
			<WeeksCounter weeks={cell.value} />
		{:else if cell.key === 'trend'}
			<Trend trend={cell.value} />
		{:else if ['active', 'ongoing'].includes(cell.key)}
			<IconSwitcher value={cell.value} yes={CheckmarkFilled} no={RadioButton} />
		{:else}{cell.value}{/if}
	</span>

	<div slot="expanded-row" let:row>
		<ExpandedUser user={asUser(row)} />
	</div>
</DataTable>
