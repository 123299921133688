<script lang="ts">
	import { Modal } from 'carbon-components-svelte'

	export let open: boolean = false
	export let size: 'xs' | 'sm' | 'lg' = 'sm'
	export let title: string = ''

	let blocked = false
	let internal = open
	$: if (open === false) {
		setTimeout(() => (internal = false), 250)
	} else {
		internal = true
	}

	const done = () => (open = false)
	const setBlocked = (state: boolean) => (blocked = state)
</script>

<Modal hasForm bind:open {size} passiveModal modalHeading={title} preventCloseOnClickOutside={blocked}>
	{#if internal}
		<slot {done} {setBlocked} />
	{/if}
</Modal>
