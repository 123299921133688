<script lang="ts">
	import { Dropdown } from 'carbon-components-svelte'
	import dayjs from 'dayjs'
	import { mean } from 'lodash'

	import { decodeScore } from '../../../lib/dna'
	import { all as allRounds } from '../../../store/rounds'
	import { all as allUsers } from '../../../store/users'
	import { all as allFeedbacks } from '../../../store/feedbacks'
	import { summaryAverage } from '@lovoo-dna/shared'

	$: totalUsers = $allUsers?.length

	const items = [
		{ id: '0', text: 'Quarterly', date: dayjs().subtract(3, 'months') },
		{ id: '1', text: 'Yearly', date: dayjs().subtract(1, 'year') },
		{ id: '2', text: 'All', date: dayjs(0) },
	]
	let limit = '0'
	$: limitDate = items.find((i) => i.id === limit)!.date.unix()
	$: relevantRounds = $allRounds?.filter((r) => r.created.seconds > limitDate && r.summary) || []
	$: relevantFeedbacks = $allFeedbacks?.filter((fb) => relevantRounds.some((round) => round.id === fb.round)) || []
	$: average = mean(relevantRounds?.map((r) => summaryAverage(r.summary!)) || [])
</script>

<section class="tc">
	<Dropdown class="mb4" style="grid-gap: 0px;" type="inline" titleText="Range" bind:selectedId={limit} {items} />

	<h4>Global DNA</h4>
	<div>
		{decodeScore(average, false)}
	</div>

	<h4>Total Rounds</h4>
	<div>
		{relevantRounds.length}
	</div>

	<h4>Total Feedbacks</h4>
	<div>
		{relevantFeedbacks.length}
	</div>

	<h4>Total LOVOOians</h4>
	<div>
		{totalUsers}
	</div>
</section>

<style>
	div {
		font-family: Consolas, monaco, monospace;
		font-size: 3rem;
		margin-top: 1rem;
		margin-bottom: 5rem;
	}
</style>
