<script lang="ts">
	import { _ } from 'svelte-i18n'
	import { DNA_VALUES, MessageSender } from '@lovoo-dna/shared'
	import { Button } from 'carbon-components-svelte'
	import Send from 'carbon-icons-svelte/lib/Send32'

	import { sendMessage, updateUser } from '../../../../firebase/functions'
	import { ownGivenCompletedFeedbacks, ownManagerOrObserverCompletedFeedbacks } from '../../../../store/feedbacks'
	import { messagesForFeedback } from '../../../../store/messages'
	import { extractRatingFromRatings } from '../../../../lib/utils'
	import { me } from '../../../../store/auth'

	import AutoHeightTextArea from '../../../../components/AutoHeightTextArea.svelte'
	import Avatar from '../../../../components/Avatar.svelte'
	import RatingComment from '../../components/RatingComment.svelte'
	import BackButton from '../../../../components/BackButton.svelte'

	export let id: string

	const messages = messagesForFeedback(id)
	$: feedbackOwn = $ownGivenCompletedFeedbacks?.find((fb) => fb.id === id) ?? null
	$: feedbackManager = $ownManagerOrObserverCompletedFeedbacks?.find((fb) => fb.id === id) || null
	$: isOwn = !!feedbackOwn
	$: feedback = isOwn ? feedbackOwn : feedbackManager

	let chatWrapper: HTMLDivElement
	let loading = false
	let message: string = ''

	async function send() {
		try {
			loading = true
			await sendMessage({ feedback: id, message })
			message = ''
		} finally {
			loading = false
		}
	}

	async function scrollToBottom() {
		if (chatWrapper) {
			chatWrapper.scrollTop = chatWrapper.scrollHeight
		}
	}

	$: {
		// Required to trigger reactivity
		$messages
		message
		scrollToBottom()
		markAsRead()
	}

	async function markAsRead() {
		const user = $me
		if (user && user.unread.includes(id)) await updateUser({ read: id })
	}
</script>

{#if feedback && feedback.result}
	<div class="mb4">
		<BackButton />
	</div>
	<div class="wrapper flex justify-center">
		<section class="mr5 overflow-auto">
			<div class="mb4">
				<Avatar display id={feedback.to} />
			</div>
			{#each DNA_VALUES as key, i}
				{#if extractRatingFromRatings(feedback.result.ratings, key)}
					{#if i !== 0}
						<hr class="mv4" />
					{/if}
					<div class="mb3 b">{$_(`dna.${key}.title`)}</div>
					<RatingComment rating={extractRatingFromRatings(feedback.result.ratings, key)} />
				{/if}
			{/each}
		</section>
		<section class="flex flex-column chat ">
			<div bind:this={chatWrapper} class="overflow-auto flex-grow mt3 mb1">
				<div class="flex flex-column justify-end min-h-100">
					{#if $messages && isOwn !== null}
						{#each $messages as message}
							<div class="message" class:me={message.from === (isOwn ? MessageSender.Employee : MessageSender.Manager)}>
								{message.message}
							</div>
						{/each}
					{/if}
				</div>
			</div>

			<fieldset disabled={loading}>
				<AutoHeightTextArea bind:value={message} rows={1} on:shiftEnter={send} />
				<div class="mt2 flex justify-end items-center">
					<div class="mr3 o-40">{$_('descriptions.sendShortcut')}</div>
					<Button icon={Send} size="small" on:click={send}>{$_('common.send')}</Button>
				</div>
			</fieldset>
		</section>
	</div>
{/if}

<style>
	.wrapper {
		height: calc(100vh - 20rem);
	}

	.chat {
		width: 100%;
		max-width: var(--max-width-xs);
	}

	.message {
		background-color: var(--cds-ui-01, #f4f4f4);
		width: fit-content;
		max-width: calc(100% - 8rem);
		padding: 0.5rem;
		margin-bottom: 0.25rem;
		white-space: pre-wrap;
	}

	.message.me {
		align-self: flex-end;
	}
</style>
