import { query, orderBy, where } from 'firebase/firestore';
import { getCollection } from '../firebase';
import { createStore } from '../firebase/adapter';
export const collection = getCollection('messages');
export const collectionSorted = query(collection, orderBy('timestamp'));
// Queries & Derived
export const messagesForFeedback = (id) => {
    const q = query(collectionSorted, where('feedback', '==', id));
    return createStore(q);
};
