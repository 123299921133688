<script lang="ts">
	import { UserRole } from '@lovoo-dna/shared'
	import { _ } from 'svelte-i18n'
	import { Form, Button, TextInput, Toggle } from 'carbon-components-svelte'
	import Save32 from 'carbon-icons-svelte/lib/Save32'
	import Reset32 from 'carbon-icons-svelte/lib/Reset32'
	import { get } from 'svelte/store'
	import { cloneDeep, isEqual } from 'lodash'

	import Select from '../../../components/Select.svelte'
	import InlineError from '../../../components/InlineError.svelte'
	import IdTag from '../../../components/IDTag.svelte'

	import { add, empty, one, update } from '../../../store/users'

	export let id: string | null = null
	export let done: Function
	export let setBlocked: Function

	let error: Error | null = null
	const original = id ? get(one)(id) : empty()
	const user = cloneDeep(original)
	$: setBlocked(!isEqual(original, user))

	async function submit(e: Event) {
		e.preventDefault()
		try {
			error = null
			if (!user) throw new Error()
			if (id) {
				await update(id, user)
			} else {
				await add(user)
			}
			done()
		} catch (e) {
			console.error(e)
			error = e
		}
	}

	async function recover() {
		if (!original || !id) return
		await update(id, { ...original, scheduledForDeletion: null })
		done()
	}

	$: roles = Object.entries(UserRole).map(([text, id]) => ({ id, text }))
</script>

{#if user}
	{#if user.scheduledForDeletion}
		<Button type="button" on:click={recover} icon={Reset32}>{$_('actions.recoverFromDeletion')}</Button>
	{:else}
		<Form on:submit={submit}>
			<TextInput labelText={$_('common.name')} bind:value={user.name} />
			<br />
			<TextInput labelText={$_('common.email')} bind:value={user.email} type="email" />
			<br />
			<Select label={$_('common.role')} items={roles} bind:value={user.role} />
			<br />
			<TextInput labelText={$_('common.comment')} bind:value={user.comment} />
			<br />
			<Toggle bind:toggled={user.active} labelText={$_('common.active')} />
			<br />
			<br />
			<Button type="submit" icon={Save32}>{$_(id ? 'actions.update' : 'actions.create')}</Button>
			<InlineError {error} />
			<IdTag {id} />
		</Form>
	{/if}
{/if}
