<script lang="ts">
	import { Button } from 'carbon-components-svelte'
	import ArrowRight from 'carbon-icons-svelte/lib/ArrowRight32'
	import { _ } from 'svelte-i18n'

	import { ownManagerOrObserverGroups } from '../../../store/groups'
	import { ownGivenCompletedFeedbacks } from '../../../store/feedbacks'
	import { ownFeedbacksWithMessages } from '../../../store/feedbacks'

	import Actions from './actions/Actions.svelte'
	import Me from './me/Me.svelte'
	import { me } from '../../../store/auth'
</script>

<h1>DNA {$_('common.dashboard')}</h1>
<div>
	{#if $ownGivenCompletedFeedbacks && $ownGivenCompletedFeedbacks.length}
		<a href="/feedbacks">
			<Button class="mv4" icon={ArrowRight}>{$_('descriptions.givenFeedbacks')}</Button>
		</a>
	{/if}
	{#if $ownManagerOrObserverGroups && $ownManagerOrObserverGroups.length}
		<a href="/teams">
			<Button class="mv4" icon={ArrowRight}>{$_('common.teams')}</Button>
		</a>
	{/if}
	{#if $ownFeedbacksWithMessages && $ownFeedbacksWithMessages.length}
		<a href="/chat">
			<Button class="mv4" icon={ArrowRight}>
				{$_('common.chats')}
				{#if $me && $me.unread.length}
					<span>{$me.unread.length}</span>
				{/if}
			</Button>
		</a>
	{/if}
</div>

<Actions />
<Me />

<style>
	span {
		font-weight: bold;
		display: inline-block;
		background-color: red;
		position: absolute;
		left: 4rem;
		width: 2.5rem;
		height: 1.5rem;
		padding: 0.2rem 0.5rem;
	}
</style>
