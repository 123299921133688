<script lang="ts">
	import { Tab } from 'carbon-components-svelte'
	import { onMount } from 'svelte'

	let ref: HTMLAnchorElement
	onMount(() => {
		ref.removeAttribute('href')
	})
</script>

<Tab {...$$restProps} bind:ref />
