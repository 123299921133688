import Fuse from 'fuse.js';
import dayjs from 'dayjs';
import { DNA_VALUES_V3, DNA_VALUES_V4 } from '../../../shared/dist';
export const DEV = __DEV__;
export function normalizeDate(date) {
    return date instanceof Date ? date : date.toDate();
}
export const sleep = (ms) => new Promise((res) => setTimeout(res, ms));
export function genValidator(validator, custom) {
    return (data, ...args) => {
        const valid = validator(data);
        if (!valid) {
            console.error(data, validator.errors);
            const msg = validator.errors && validator.errors[0].message;
            throw new Error(msg || 'Unknown Error in Validation');
        }
        if (custom)
            return custom(data, ...args);
    };
}
export class FuzzySearch {
    constructor(data, keys) {
        this.items = data;
        this.idx = new Fuse(data, { keys: keys, threshold: 0.4 });
    }
    search(needle) {
        if (!needle || needle.length < 3)
            return this.items;
        return this.idx.search(needle).map((r) => r.item);
    }
}
export function formatDate(date) {
    return dayjs(date).utc().format('DD/MM/YYYY');
}
export function extractRatingFromRatings(ratings, key) {
    var _a, _b;
    if (ratings.version === 4) {
        return (_a = ratings[key]) !== null && _a !== void 0 ? _a : null;
    }
    else {
        return (_b = ratings[key]) !== null && _b !== void 0 ? _b : null;
    }
}
export function getSortedKeysFromRatings(object) {
    return object.version === 4 ? DNA_VALUES_V4 : DNA_VALUES_V3;
}
