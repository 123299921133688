<script lang="ts">
	import { _ } from 'svelte-i18n'

	import type { AnyDNAValue } from '../lib/dna'
	import { DNA_SUMMARY } from '../lib/dna'

	export let type: AnyDNAValue
</script>

<div {...$$restProps}>
	<div class="flex items-center">
		<img src="/assets/icons/{type.toLowerCase()}.svg" alt={type} />
		<h3>
			{#if type === DNA_SUMMARY}
				DNA {$_('common.score')}
			{:else}
				{$_(`dna.${type}.title`)}
			{/if}
			<slot />
		</h3>
	</div>
</div>

<style>
	img {
		height: 3.5em;
		width: 3.5em;
		margin-right: 0.5rem;
		object-fit: contain;
	}
</style>
