<script lang="ts">
	import { TextArea, Button, Toggle, Tile } from 'carbon-components-svelte'
	import { _ } from 'svelte-i18n'
	import { updateRound } from '../../../../firebase/functions'

	import type { RoundClient } from '../../../../store/rounds'

	export let round: RoundClient

	let loading = false

	async function save() {
		try {
			loading = true
			await updateRound({
				id: round.id,
				data: {
					comment: round.comment,
					discussed: round.discussed,
				},
			})
		} finally {
			loading = false
		}
	}
</script>

<Tile>
	<fieldset disabled={loading}>
		<TextArea
			light
			rows={3}
			labelText="{$_('common.comment')} ({$_('descriptions.visibleForEmployee')})"
			bind:value={round.comment}
		/>
		<br />
		<Toggle bind:toggled={round.discussed} labelText={$_('common.discussed')} />
		<br />
		<Button class="fr" size="small" on:click={save}>{$_('actions.save')}</Button>
	</fieldset>
</Tile>
