<script lang="ts">
	import { Button } from 'carbon-components-svelte'
	import { router } from 'tinro'
	import { UserRole } from '@lovoo-dna/shared'
	import { _ } from 'svelte-i18n'

	import ThemeSwitcher from './components/ThemeSwitcher.svelte'
	import Account from './components/Account.svelte'
	import Logo from './components/Logo.svelte'

	import { me } from '../../store/auth'

	$: isAdmin = $router.path.startsWith('/admin')
</script>

<nav class="flex justify-between">
	<Logo />
	<div class="flex">
		<ThemeSwitcher />
		<Account />
		{#if $me && $me.role === UserRole.Admin}
			<a href={isAdmin ? '/' : '/admin'}>
				<Button size="small">{isAdmin ? $_('common.home') : $_('common.admin')}</Button>
			</a>
		{/if}
	</div>
</nav>

<style>
	nav {
		--from: #ffffff;
		--to: #ffffff00;
		position: fixed;
		top: 0;
		right: 0;
		width: 100%;
		height: 5.5em;
		padding: 1.5rem;
		background: var(--cds-ui-background, #ffffff);
		background: linear-gradient(180deg, var(--from) 70%, var(--to)) 100%;
		z-index: 1000;
	}

	:global(html[theme='g10']) nav {
		--from: #f4f4f4;
		--to: #f4f4f400;
	}
	:global(html[theme='g90']) nav {
		--from: #262626;
		--to: #26262600;
	}
	:global(html[theme='g100']) nav {
		--from: #161616;
		--to: #16161600;
	}

	div > :global(*) {
		margin-left: 1.5em;
	}
</style>
