<script lang="ts">
	import dayjs from 'dayjs'
	import { DatePicker, DatePickerInput } from 'carbon-components-svelte'
	import { _ } from 'svelte-i18n'
	import type { DatePickerInputProps } from 'carbon-components-svelte/types/DatePicker/DatePickerInput.svelte'
	import { createEventDispatcher } from 'svelte'

	export let date = new Date()
	export let label = $_('common.date')
	export let size: DatePickerInputProps['size'] = undefined

	const dispatch = createEventDispatcher()
	const format = 'DD/MM/YYYY'

	let internal: any = dayjs(date).utc().format(format)

	function change(e: CustomEvent) {
		const dateString = e.detail.dateStr as string
		date = dayjs.utc(dateString, format).toDate()
		dispatch('change', e.detail)
	}
</script>

<div {...$$restProps}>
	<DatePicker
		datePickerType="single"
		value={internal}
		dateFormat="d/m/Y"
		on:change={change}
		flatpickrProps={{ static: true }}
	>
		<DatePickerInput {size} labelText={label} />
	</DatePicker>
</div>

<style>
	div :global(.bx--date-picker-container),
	div :global(.bx--date-picker.bx--date-picker--single),
	div :global(.bx--date-picker__input),
	div :global(.bx--date-picker--single.bx--date-picker) {
		width: 100%;
	}
</style>
