import { initializeApp } from 'firebase/app';
import { initializeFirestore, connectFirestoreEmulator, enableMultiTabIndexedDbPersistence, collection, } from 'firebase/firestore';
import { browserLocalPersistence, initializeAuth, connectAuthEmulator } from 'firebase/auth';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
export { Timestamp } from 'firebase/firestore';
import { FN_REGION } from '@lovoo-dna/shared';
import config from './config';
import { DEV } from '../lib/utils';
export const App = initializeApp(config);
export const DB = initializeFirestore(App, { ignoreUndefinedProperties: true });
export const Auth = initializeAuth(App, { persistence: browserLocalPersistence });
export const Functions = getFunctions(App, FN_REGION);
export function getCollection(name) {
    return collection(DB, name);
}
if (DEV) {
    connectFirestoreEmulator(DB, 'localhost', 8080);
    connectAuthEmulator(Auth, 'http://localhost:9099/');
    connectFunctionsEmulator(Functions, 'localhost', 5001);
}
export const init = Promise.all([enableMultiTabIndexedDbPersistence(DB)]);
