<script lang="ts">
	import { Tile, Button } from 'carbon-components-svelte'
	import { GoogleAuthProvider, getAuth, browserPopupRedirectResolver, signInWithPopup } from 'firebase/auth'
	import { _ } from 'svelte-i18n'

	let loading = false
	let error: Error | null = null

	async function login() {
		try {
			loading = false
			error = null
			const auth = getAuth()
			const provider = new GoogleAuthProvider()
			await signInWithPopup(auth, provider, browserPopupRedirectResolver)
		} catch (e) {
			error = e
			console.error(e)
		} finally {
			loading = false
		}
	}
</script>

<div class="wrapper center pa3 tc">
	<Tile>
		<h1>LOVOO DNA</h1>
		<class class="mt4" />
		<fieldset disabled={loading}>
			<Button kind="secondary" on:click={login}>Sign in with Google</Button>
		</fieldset>
		{#if error}
			<p class="red">{error.message}</p>
		{/if}
	</Tile>
</div>

<style>
	.wrapper {
		max-width: var(--max-width-xs);
	}
</style>
