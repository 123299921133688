<script lang="ts">
	import { router } from 'tinro'
	import UserAvatar from 'carbon-icons-svelte/lib/UserAvatarFilled32'

	import { getUserName, one } from '../store/users'

	export let id: string
	export let href: string | null = null
	export let small = false
	export let display = false

	$: user = $one(id)
	$: navigate = () => {
		if (href) router.goto(href)
	}
	$: name = $getUserName(user?.id || '')
</script>

{#if user !== null}
	<div {...$$restProps} class:small class:display class:link={href} on:click={navigate}>
		{#if user && user.photoURL}
			<img class="img" src={user.photoURL} alt="profile" />
		{:else}
			<UserAvatar class="img" />
		{/if}
		{#if display}
			<h2 class="ml3">{name}</h2>
		{:else}
			<span>{name}</span>
		{/if}
	</div>
{/if}

<style>
	div {
		display: inline-flex;
		justify-content: center;
		align-items: center;
	}

	div.small {
		margin: 0.5rem;
	}

	div.link {
		cursor: pointer;
	}

	div.link :global(*) {
		transition: all 100ms ease-in-out;
	}

	:global(.img) {
		border: 2px solid var(--cds-ui-03, #f4f4f4);
		height: 3rem;
		width: 3rem;
		border-radius: 100%;
		object-fit: cover;
		object-position: center;
		z-index: 1;
	}

	div.link:hover :global(.img) {
		transform: scale(1.2);
	}

	div.display :global(.img) {
		width: 6rem;
		height: 6rem;
	}

	div.small :global(.img) {
		height: 1.75rem;
		width: 1.75rem;
	}

	div.small span {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}

	span {
		background-color: var(--cds-ui-01, #f4f4f4);
		border: 1px solid var(--cds-ui-03, #f4f4f4);
		border-radius: 2rem;
		display: inline;
		padding: 0.25rem 0.5rem;
		padding-left: 1rem;
		position: relative;
		left: -0.75rem;
		z-index: 0;
		margin-right: -0.25rem;
	}

	div.link:hover span {
		box-shadow: -1rem 0 2rem rgb(0 0 0 / 5%);
	}
</style>
