<script lang="ts">
	import { _ } from 'svelte-i18n'
	import Pen from 'carbon-icons-svelte/lib/Pen24'

	import { ownOpenGivenFeedbacks } from '../../../../store/feedbacks'
	import { getUserName } from '../../../../store/users'
	import { formatDate } from '../../../../lib/utils'

	import TilePrompt from '../../../../components/TilePrompt.svelte'

	$: feedbacks = $ownOpenGivenFeedbacks
</script>

{#if feedbacks && feedbacks.length}
	<section>
		{#each feedbacks as { id, deadline, to } (id)}
			<TilePrompt icon={Pen} button={$_('actions.evaluate')} href="/submit/{id}">
				{$_('actions.giveFeedbackUntil', {
					values: { date: formatDate(deadline.toDate()), for: $getUserName(to) },
				})}
			</TilePrompt>
		{/each}
	</section>
{/if}
