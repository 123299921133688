<script lang="ts">
	import { DNA_VALUES, UserRole } from '@lovoo-dna/shared'

	import type { DataTableRow } from 'carbon-components-svelte/types/DataTable/DataTable.svelte'
	import { DataTable, Toolbar, ToolbarContent, Button } from 'carbon-components-svelte'
	import { _ } from 'svelte-i18n'
	import Right from 'carbon-icons-svelte/lib/ArrowRight32'

	import { getSmileyForValue } from '../../../../lib/dna'
	import { ownManagerOrObserverFeedbacksForRound } from '../../../../store/feedbacks'
	import type { RoundClient } from '../../../../store/rounds'
	import { getUserName } from '../../../../store/users'
	import { isAdmin, me } from '../../../../store/auth'
	import { formatDate, getSortedKeysFromRatings } from '../../../../lib/utils'

	export let round: RoundClient

	$: keys = round.summary ? getSortedKeysFromRatings(round.summary) : []
	$: headersDNA = keys.map((value) => ({ key: value, value: $_(`dna.${value}.title`) }))
	$: headers = [{ key: 'frequency', value: $_('common.frequency') }, ...headersDNA]

	$: if ($me && $me.role === UserRole.Admin) {
		headers = [{ key: 'name', value: $_('common.name') }, ...headers]
	}

	$: feedbacks = ownManagerOrObserverFeedbacksForRound(round.id)

	let rows: DataTableRow[] | null = null
	$: if ($feedbacks) {
		rows = $feedbacks
			.filter((fb) => fb.result)
			.map((fb) => {
				const ratings = Object.fromEntries(
					keys.map((value) => {
						const rating = fb.result?.ratings[value]?.rating
						return [value, rating ? getSmileyForValue(rating) : '❓']
					})
				)
				const row: DataTableRow = {
					...ratings,
					name: $isAdmin || !fb.result?.anonymous ? $getUserName(fb.from) : $_('descriptions.deletedAnonymous'),
					frequency: fb.result ? $_('dna.frequency.values.' + fb.result.frequency) : '❓',
					id: fb.id,
				}
				return row
			})
	}
</script>

{#if rows}
	<div {...$$restProps}>
		<h3>{formatDate(round.created.toDate())}</h3>
		<DataTable size="short" {headers} {rows}>
			<span slot="cell" let:cell>
				{#if DNA_VALUES.includes(cell.key)}
					<div class="tc">{cell.value}</div>
				{:else}
					{cell.value}
				{/if}
			</span>

			<Toolbar size="sm">
				<ToolbarContent>
					<Button icon={Right} href="/round/{round.id}">{$_('common.details')}</Button>
				</ToolbarContent>
			</Toolbar>
		</DataTable>
	</div>
{/if}

<style>
	div {
		position: relative;
	}

	h3 {
		position: absolute;
		top: 0.125rem;
		left: 0;
		z-index: 1;
		background: var(--cds-text-01, #161616);
		display: inline-block;
		color: var(--cds-ui-background, #ffffff);
		padding: 0.375rem;
		font-size: 1rem;
	}
</style>
