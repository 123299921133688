<script lang="ts">
	import { onMount } from 'svelte'
	import { router } from 'tinro'
	import { ProgressIndicator, ProgressStep, Loading } from 'carbon-components-svelte'
	import { _ } from 'svelte-i18n'
	import type { FeedbackResult } from '@lovoo-dna/shared'
	import { DNA_VALUES_V4 } from '@lovoo-dna/shared'
	import { isEqual, cloneDeep } from 'lodash'

	import type { FeedbackClient } from '../../../../store/feedbacks'
	import { AnyDNAValue, isStepValid } from '../../../../lib/dna'
	import { emptyResult, ownOneFeedback } from '../../../../store/feedbacks'
	import { getUserName } from '../../../../store/users'
	import Step from './components/Step.svelte'
	import Intro from './components/Intro.svelte'
	import Summary from './components/Summary.svelte'
	import { submitFeedback } from '../../../../firebase/functions'
	import { notify } from '../../../../components/Notifications.svelte'
	import Spinner from '../../../../components/Spinner.svelte'

	type TStep = { key: string; name: string; complete: boolean }

	export let id: string
	let step: number = 0
	let key: AnyDNAValue
	let steps: TStep[] | null = null
	let last: FeedbackResult | null = null
	let loading = false
	let error: Error | null = null

	function forward() {
		if (step < DNA_VALUES_V4.length) step++
		else save(false)
	}
	function backward() {
		if (step >= 0) step--
	}
	async function save(draft = true) {
		try {
			if (!feedback || !feedback.result || !steps) return
			if (draft) {
				error = null
				if (isEqual(last, feedback.result)) return
				last = cloneDeep(feedback.result)
			} else {
				error = null
				if (loading) return
				loading = true
			}

			await submitFeedback({ result: feedback.result, draft, id })
			notify({
				title: $_(draft ? 'descriptions.draftSaved' : 'descriptions.feedbackSubmitted'),
				kind: draft ? 'info' : 'success',
				timeout: draft ? 2000 : 5000,
			})
			if (!draft) {
				router.goto('/')
			}
		} catch (e) {
			error = e
		} finally {
			loading = false
		}
	}

	let feedback: FeedbackClient | null = null
	$: if (feedback && !feedback.result) feedback.result = emptyResult()
	$: if (feedback && feedback.result && !steps) {
		const tmp = [
			...DNA_VALUES_V4.map((value) => ({
				key: value,
				name: $_(`dna.${value}.title`),
				// @ts-ignore waiting for ts 4.4 to infer the version of ratings
				complete: isStepValid(feedback?.result?.ratings[value])[0],
			})),
			{
				key: 'summary',
				name: $_('common.summary'),
				complete: false,
			},
		] as TStep[]

		// Find highest valid step and select it
		let highest = -1
		for (let i = 0; i < DNA_VALUES_V4.length; ++i) {
			if (tmp[i].complete) highest = i
		}
		if (highest === DNA_VALUES_V4.length - 1) highest++ // If last step is valid go to the summary

		last = cloneDeep(feedback.result)
		step = highest
		steps = tmp
	}
	$: if (steps && step >= 0) key = steps[step].key as AnyDNAValue
	$: if (steps) {
		const allStepsValid = steps.slice(0, DNA_VALUES_V4.length).reduce((acc, cur) => acc && cur.complete, true)
		steps[5].complete = allStepsValid
	}

	onMount(() => {
		const unsubscribe = ownOneFeedback.subscribe((fn) => {
			const fb = fn(id)
			if (fb) {
				unsubscribe()
				feedback = fb
			}
		})

		const interval = setInterval(save, 15 * 1000)
		return () => clearInterval(interval)
	})
</script>

{#if loading}
	<Loading />
{/if}
{#if feedback && steps}
	<h1>{$_('descriptions.feedbackFor', { values: { name: $getUserName(feedback.to) } })}</h1>

	<div class="progress">
		<ProgressIndicator spaceEqually bind:currentIndex={step}>
			{#each steps as { name, key, complete } (key)}
				<ProgressStep label={name} {complete} />
			{/each}
		</ProgressIndicator>
	</div>

	<div class="content mt5 center">
		{#if feedback.result}
			{#if step === -1}
				<Intro {forward} result={feedback.result} />
			{:else if step < 5}
				<Step
					bind:rating={feedback.result.ratings[key]}
					{key}
					{forward}
					{backward}
					on:validate={(e) => {
						if (steps) steps[step].complete = e.detail
					}}
				/>
			{:else}
				<Summary {error} {forward} {backward} result={feedback.result} to={$getUserName(feedback.to)} />
			{/if}
		{/if}
	</div>
{:else}
	<Spinner />
{/if}

<style>
	.progress {
		overflow: auto;
		width: 100%;
		-ms-overflow-style: none;
		scrollbar-width: none;
	}

	.progress::-webkit-scrollbar {
		display: none;
	}

	.content {
		max-width: var(--max-width-sm);
	}
</style>
