<script lang="ts">
	import { ComboBox } from 'carbon-components-svelte'
	import type { ComboBoxItem, ComboBoxProps } from 'carbon-components-svelte/types/ComboBox/ComboBox.svelte'

	import type { UserClient } from '../store/users'
	import { all } from '../store/users'

	export let label: string = ''
	export let selected: string | undefined = undefined
	export let allowed: string[] | null = null
	export let disallowed: string[] | null = null
	export let size: ComboBoxProps['size'] = undefined
	export let filter: (user: UserClient) => boolean = () => true

	$: items = $all
		? $all
				.filter(filter)
				.filter((user) => (!allowed || allowed.includes(user.id)) && (!disallowed || !disallowed.includes(user.id)))
				.map((user) => ({ id: user.id, text: user.name }))
		: null

	function shouldFilterItem(item: ComboBoxItem, value: string) {
		// Search function
		if (!value) return true
		return item.text.toLowerCase().includes(value.toLowerCase())
	}
</script>

{#if items}
	<ComboBox {...$$restProps} {size} light titleText={label} bind:selectedId={selected} {shouldFilterItem} {items} />
{/if}
