<script lang="ts">
	import { _ } from 'svelte-i18n'
	import Checkmark from 'carbon-icons-svelte/lib/CheckmarkFilled20'
	import { Tile, ExpandableTile } from 'carbon-components-svelte'
	import Close from 'carbon-icons-svelte/lib/CloseFilled20'

	import { summaryAverage } from '@lovoo-dna/shared'
	import { decodeScore, DNA_SUMMARY } from '../../../../lib/dna'
	import type { RoundClient } from '../../../../store/rounds'
	import { ownFeedbacksForRound } from '../../../../store/feedbacks'
	import { extractRatingFromRatings, formatDate, getSortedKeysFromRatings } from '../../../../lib/utils'

	import RatingComment from '../../components/RatingComment.svelte'
	import Avatar from '../../../../components/Avatar.svelte'

	export let round: RoundClient

	$: average = round.summary ? decodeScore(summaryAverage(round.summary)) : ''
	$: feedbacks = $ownFeedbacksForRound(round.id) || []
</script>

<div {...$$restProps}>
	<div class="flex mb2">
		<h3 class="pa2 f5">{formatDate(round.created.toDate())}</h3>
		<div class="flex items-center justify-center ml3 ">
			<div>{$_('common.discussed')}:</div>
			<div class="ml2 ">
				{#if round.discussed}
					<Checkmark />
				{:else}
					<Close />
				{/if}
			</div>
		</div>
	</div>
	{#if round.comment}
		<Tile class="mb2">
			<h5 class="">{$_('common.comment')}</h5>
			<p>
				{round.comment}
			</p>
		</Tile>
	{/if}
	<Tile>
		<div class="flex justify-between flex-wrap pa3">
			{#if round.summary}
				{#each [DNA_SUMMARY, ...getSortedKeysFromRatings(round.summary)] as type}
					<div class="tc">
						<img class="mb2" src="/assets/icons/{type.toLowerCase()}.svg" alt={type} />
						<p class="code f3">
							{#if type === DNA_SUMMARY}
								{average}
							{:else}
								{decodeScore(round.summary[type])}
							{/if}
						</p>
					</div>
				{/each}
			{/if}
		</div>
	</Tile>
	{#if feedbacks.length}
		<ExpandableTile class="mt2">
			<div slot="above" style="height: 1rem">
				<h5>{$_('descriptions.feedbacksSubmittedPublicly')}</h5>
			</div>
			<div slot="below" style="height: 100%" class="pv4">
				{#each feedbacks as feedback, i (feedback.from)}
					{#if feedback.result}
						<div class:mt3={i !== 0}>
							<Tile light>
								<Avatar class="mb3" id={feedback.from} />
								{#each [...getSortedKeysFromRatings(feedback.result.ratings)] as key, i (key)}
									{#if i !== 0}
										<hr class="mv3" />
									{/if}
									<h4 class="mb2">{$_(`dna.${key}.title`)}</h4>
									<RatingComment rating={extractRatingFromRatings(feedback.result.ratings, key)} />
								{/each}
								{#if feedback.result.note}
									<hr class="mv3" />
									<h4 class="mb2">{$_(`common.note`)}</h4>
									<p>{feedback.result.note}</p>
								{/if}
							</Tile>
						</div>
					{/if}
				{/each}
			</div>
		</ExpandableTile>
	{/if}
</div>

<style>
	h3 {
		background: var(--cds-text-01, #161616);
		display: inline-block;
		color: var(--cds-ui-background, #ffffff);
	}

	img {
		height: 6rem;
		width: 6rem;
		object-fit: contain;
		object-position: center;
	}
</style>
