import { UserRole } from '@lovoo-dna/shared';
import { derived, readable } from 'svelte/store';
import { terminate, clearIndexedDbPersistence, doc, getDoc } from 'firebase/firestore';
import { one, collection } from './users';
import { Auth, DB } from '../firebase';
import { deleteOwnAuthUser, updateUser } from '../firebase/functions';
import { sleep } from '../lib/utils';
import { setLocale } from '../locale';
export const auth = readable(undefined, (set) => {
    Auth.onAuthStateChanged((auth) => {
        set(auth);
    });
});
const waitForCustomClaims = (user, retries = 5) => new Promise(async (resolve) => {
    let token = await user.getIdTokenResult();
    while (retries) {
        if (token.claims.link)
            return resolve(token.claims);
        retries--;
        await sleep(1000);
        token = await user.getIdTokenResult(true); // retry by forcing refresh
    }
    resolve(null);
});
export const me = derived(auth, (auth, set) => {
    if (auth) {
        waitForCustomClaims(auth).then(async (claims) => {
            var _a;
            if (!claims) {
                set(false);
            }
            else {
                try {
                    const user = await getDoc(doc(collection, claims.link));
                    if ((_a = user.data()) === null || _a === void 0 ? void 0 : _a.active) {
                        one.subscribe((fn) => {
                            const user = fn(claims.link);
                            set(user ? Object.assign(Object.assign({}, user), { claims }) : false);
                        });
                    }
                    else {
                        set(false);
                    }
                }
                catch (e) {
                    console.error('Could not log in, removing auth account', e);
                    // Wrong Link in claim => cannot find user
                    deleteOwnAuthUser({})
                        .then(() => {
                        window.location.reload();
                    })
                        .catch(() => set(false));
                }
            }
        });
    }
}, null);
export const isAdmin = derived(me, ($me) => $me && $me.role === UserRole.Admin);
export async function logout() {
    await Auth.signOut();
    await terminate(DB);
    await clearIndexedDbPersistence(DB);
    window.location.href = '/';
}
me.subscribe((me) => {
    if (me)
        setLocale(me.locale);
});
// Update photo if the current is not up date.
derived([auth, me], ([auth, me]) => auth && me && auth.photoURL && me.photoURL !== auth.photoURL ? auth.photoURL : null).subscribe((photoURL) => {
    if (photoURL)
        updateUser({ photoURL });
});
// Reload page when newly signed in.
let last = undefined;
auth.subscribe((auth) => {
    if (last === null && !!auth)
        window.location.reload();
    last = auth;
});
