<script lang="ts">
	import { Feedback, FeedbackState, Round, RoundState, RoundStateValues } from '@lovoo-dna/shared'
	import { _ } from 'svelte-i18n'
	import { Form, Button } from 'carbon-components-svelte'
	import Save20 from 'carbon-icons-svelte/lib/Save20'
	import { get } from 'svelte/store'
	import CloseFilled32 from 'carbon-icons-svelte/lib/CloseFilled32'
	import { cloneDeep, isEqual } from 'lodash'
	import dayjs from 'dayjs'
	import { doc, writeBatch } from '@firebase/firestore'

	import { DB, Timestamp } from '../../../firebase'
	import { addPeersToRound } from '../../../firebase/functions'
	import { one, collection as roundCollection } from '../../../store/rounds'
	import { ownManagerOrObserverFeedbacksForRound, collection as feedbackCollection } from '../../../store/feedbacks'

	import Select from '../../../components/Select.svelte'
	import InlineError from '../../../components/InlineError.svelte'
	import Spinner from '../../../components/Spinner.svelte'
	import SingleUserSelect from '../../../components/SingleUserSelect.svelte'
	import EmptyNotice from '../../../components/EmptyNotice.svelte'
	import IDTag from '../../../components/IDTag.svelte'
	import RoundFormFeedback from './RoundFormFeedback.svelte'

	export let id: string
	export let done: Function
	export let setBlocked: Function

	const feedbacksStore = ownManagerOrObserverFeedbacksForRound(id)
	let loading = false
	let error: Error | null = null
	let newFeedbacks: string[] = []
	let deleted: string[] = []
	let changed: Record<string, Date> = {}
	$: feedbacks = $feedbacksStore
	$: round = cloneDeep(get(one)(id))
	$: totalFeedbacks = newFeedbacks.length + (feedbacks?.filter((fb) => !deleted.includes(fb.id)).length || 0)
	$: disallowed = [...newFeedbacks, ...(feedbacks || []).map((fb) => fb.from)]
	$: canAddMore = totalFeedbacks < 6
	$: setBlocked(!isEqual(get(one)(id), round) || newFeedbacks.length || deleted.length || Object.keys(changed).length)

	let newFeedback: string = ''

	function add() {
		if (canAddMore && newFeedback) {
			newFeedbacks = [...newFeedbacks, newFeedback]
			newFeedback = ''
		}
	}

	async function submit(e: Event) {
		e.preventDefault()
		try {
			loading = true
			error = null
			const original = get(one)(id)
			if (!round || !original || !feedbacks) throw new Error()
			if (newFeedbacks.length) {
				await addPeersToRound({ id: round.id, peers: newFeedbacks })
			}
			const batch = writeBatch(DB)
			if (deleted) {
				for (const id of deleted) {
					batch.delete(doc(feedbackCollection, id))
				}
			}
			let reopenRound = false
			for (const feedback of feedbacks) {
				if (feedback.id in changed) {
					const original = dayjs(feedback.deadline.toDate())
					const date = dayjs(changed[feedback.id])
					if (!original.isSame(date, 'day')) {
						const deadline = Timestamp.fromMillis(date.endOf('day').valueOf())
						const diff: Partial<Feedback> = { deadline }
						if (feedback.state === FeedbackState.Completed) {
							diff.state = FeedbackState.Active
							reopenRound = true
						}
						batch.update(doc(feedbackCollection, feedback.id), diff)
					}
				}
			}
			if (reopenRound) {
				const diff: Partial<Round> = { state: RoundState.Active }
				batch.update(doc(roundCollection, id), diff)
			} else if (original.state !== round.state) {
				const diff: Partial<Round> = { state: round.state }
				batch.update(doc(roundCollection, id), diff)
			}
			await batch.commit()
			done()
		} catch (e) {
			console.error(e)
			error = e
		} finally {
			loading = false
		}
	}

	const states = RoundStateValues.map((val) => ({ id: val, text: $_('common.roundState.' + val) }))
</script>

{#if round === null || feedbacks === null}
	<Spinner />
{:else}
	<fieldset disabled={loading}>
		<Form on:submit={submit}>
			<Select size="sm" label={$_('common.state')} items={states} bind:value={round.state} readonly />
			<br />
			{#each feedbacks as feedback}
				<br />
				<RoundFormFeedback
					{feedback}
					deleted={deleted.includes(feedback.id)}
					remove={() => {
						deleted = [...deleted, feedback.id]
					}}
					on:date={(date) => (changed[feedback.id] = date.detail)}
				/>
			{/each}
			{#each newFeedbacks as feedback, i}
				<br />
				<RoundFormFeedback
					{feedback}
					remove={() => {
						newFeedbacks.splice(i, 1)
						newFeedbacks = [...newFeedbacks] // Svelte needs assignments to trigger changes
					}}
				/>
			{/each}

			<div class="mt4">
				{#if canAddMore}
					<h6>{$_('descriptions.addPeer')}</h6>
					<div class="flex items-end">
						<SingleUserSelect
							label={$_('common.peer')}
							size="sm"
							{disallowed}
							class="flex-grow w5"
							bind:selected={newFeedback}
						/>
						<Button size="small" class="ml1" on:click={add} disabled={!newFeedback}>{$_('common.add')}</Button>
					</div>
				{:else}
					<EmptyNotice icon={CloseFilled32} message={$_('descriptions.cannotAddMorePeers')} />
				{/if}
			</div>

			<br />
			<br />
			<Button type="submit" icon={Save20}>{$_(id ? 'actions.update' : 'actions.create')}</Button>
			<InlineError {error} />

			<IDTag {id} />
		</Form>
	</fieldset>
{/if}
