import locales from '@lovoo-dna/locale';
import { addMessages, init, getLocaleFromNavigator, locale } from 'svelte-i18n';
for (const [locale, data] of Object.entries(locales)) {
    addMessages(locale, data);
}
export const AvailableLanguages = Object.keys(locales);
init({
    fallbackLocale: 'en',
    initialLocale: getLocaleFromNavigator(),
});
export function setLocale(code) {
    locale.set(code && AvailableLanguages.includes(code) ? code : getLocaleFromNavigator());
}
