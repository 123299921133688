<script lang="ts">
	import { _ } from 'svelte-i18n'

	import Dna from '../dna/DNA.svelte'
</script>

<section>
	<h2 class="mb3 mt5">{$_('common.me')}</h2>
	<Dna />
</section>
