import { query, where } from 'firebase/firestore';
import { derived } from 'svelte/store';
import lodash from 'lodash';
import { createStore } from '../firebase/adapter';
// Function to wrap 'in' firebase query, as it supports a maximum of 10 elements
export function universalInClause(collection, field, items) {
    const chunks = lodash.chunk(items, 10);
    const queries = chunks.map((chunk) => query(collection, where(field, 'in', chunk)));
    const stores = queries.map((q) => createStore(q));
    return derived(stores, ($stores, set) => {
        let all = null;
        $stores.forEach((store) => {
            if (store) {
                if (all === null)
                    all = [];
                all.push(...store);
            }
        });
        set(all);
    });
}
