<script lang="ts">
	import { _ } from 'svelte-i18n'
	import TilePrompt from '../../../../components/TilePrompt.svelte'
	import Chat from 'carbon-icons-svelte/lib/Chat32'

	import { ownFeedbacksWithMessages } from '../../../../store/feedbacks'
	import { getUserName } from '../../../../store/users'
	import BackButton from '../../../../components/BackButton.svelte'
	import EmptyNotice from '../../../../components/EmptyNotice.svelte'
</script>

{#if $ownFeedbacksWithMessages}
	<BackButton />
	<h1 class="mv4">{$_('common.chats')}</h1>
	{#if $ownFeedbacksWithMessages.length}
		{#each $ownFeedbacksWithMessages as feedback (feedback.id)}
			<TilePrompt icon={Chat} button={$_('common.chat')} href="/chat/{feedback.id}">
				{$getUserName(feedback.to)}
			</TilePrompt>
		{/each}
	{:else}
		<EmptyNotice icon={Chat} message={$_('descriptions.noChats')} />
	{/if}
{/if}
