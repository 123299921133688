<script lang="ts">
	import { TextArea } from 'carbon-components-svelte'
	import { createEventDispatcher, onMount } from 'svelte'

	export let value: string

	const dispatch = createEventDispatcher()
	let ref: HTMLTextAreaElement
	let initial = 0
	$: if (ref) {
		value
		resize()
	}

	function resize() {
		ref.style.height = '1px'
		ref.style.height = `${Math.max(ref.scrollHeight + 1, initial)}px`
	}

	onMount(() => {
		initial = ref.offsetHeight
		const intercept = (e: KeyboardEvent) => {
			if (e.key === 'Enter' && e.shiftKey) dispatch('shiftEnter')
		}
		ref.addEventListener('keydown', intercept)
		return () => {
			ref.removeEventListener('keydown', intercept)
		}
	})
</script>

<TextArea bind:ref bind:value {...$$restProps} />
