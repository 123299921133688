<script type="ts">
	import type { DropdownItem } from 'carbon-components-svelte/types/Dropdown/Dropdown.svelte'
	import { Dropdown } from 'carbon-components-svelte'

	export let label: string = ''
	export let value: string
	export let items: DropdownItem[]
</script>

<Dropdown {...$$restProps} titleText={label} bind:selectedId={value} {items} />
