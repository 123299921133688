import { onSnapshot } from 'firebase/firestore';
import { readable } from 'svelte/store';
function convert(doc) {
    return Object.assign(Object.assign({}, doc.data()), { id: doc.id });
}
export function createStore(query) {
    const store = readable(null, (set) => {
        const internal = [];
        onSnapshot(query, (snap) => {
            snap.docChanges().forEach((doc) => {
                const converted = convert(doc.doc);
                switch (doc.type) {
                    case 'added':
                        internal.splice(doc.newIndex, 0, converted);
                        break;
                    case 'modified':
                        if (doc.newIndex === doc.oldIndex)
                            internal[doc.newIndex] = converted;
                        else {
                            internal.splice(doc.oldIndex, 1);
                            internal.splice(doc.newIndex, 0, converted);
                        }
                        break;
                    case 'removed':
                        internal.splice(doc.oldIndex, 1);
                        break;
                }
            });
            set(internal);
        });
    });
    return store;
}
