<script lang="ts">
	import { _ } from 'svelte-i18n'
	import Icon from 'carbon-icons-svelte/lib/QuadrantPlot32'
	import { Button } from 'carbon-components-svelte'

	import {
		ownCompletedRounds,
		oneOwnManagerOrObserverCompletedRoundsForUser,
		getCompletedRoundsForUser,
	} from '../../../../store/rounds'
	import { aggregateUserRounds, DNAWithSummary, DNA_SUMMARY } from '../../../../lib/dna'
	import { isAdmin, me } from '../../../../store/auth'

	import BackButton from '../../../../components/BackButton.svelte'
	import Avatar from '../../../../components/Avatar.svelte'
	import Spinner from '../../../../components/Spinner.svelte'
	import PlotBox from './PlotBox.svelte'
	import RoundTable from './RoundTable.svelte'
	import RoundEmployee from './RoundEmployee.svelte'
	import EmptyNotice from '../../../../components/EmptyNotice.svelte'

	export let id: string | null = null
	$: ownId = $me && $me.id
	$: isOwn = id === ownId
	// TODO: Fix runtime unauthorized as user
	$: rounds =
		id && !isOwn
			? $isAdmin
				? $getCompletedRoundsForUser(id)
				: $oneOwnManagerOrObserverCompletedRoundsForUser(id)
			: $ownCompletedRounds
	$: aggregated = rounds ? aggregateUserRounds(rounds) : null
</script>

{#if !rounds}
	<Spinner />
{:else if rounds.length}
	<section>
		{#if id}
			<BackButton />
			<div class="mv5">
				<Avatar {id} display />
			</div>
		{:else}
			<Button icon={Icon} href="/dna/{ownId}">{$_('common.details')}</Button>
		{/if}
		<div class="wrapper mv3">
			{#each DNAWithSummary as type}
				{#if aggregated}
					<PlotBox {type} data={aggregated.data.filter((entry) => entry.group === type)}>
						{#if type === DNA_SUMMARY}
							<span class="code">{aggregated.dna.toFixed(0)}</span>
						{/if}
					</PlotBox>
				{/if}
			{/each}
		</div>
	</section>
	{#if id}
		<section class="mt5">
			<h2>{$_('common.rounds')}</h2>
			{#each rounds as round}
				{#if isOwn}
					<RoundEmployee class="mv5" {round} />
				{:else}
					<RoundTable class="mv4" {round} />
				{/if}
			{/each}
		</section>
		<BackButton />
	{/if}
{:else}
	<EmptyNotice icon={Icon} message={$_('descriptions.noFeedbackForNow')} />
{/if}

<style>
	.wrapper {
		display: grid;
		gap: 4rem 2rem;
		grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
	}
</style>
