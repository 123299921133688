import { UserChannel, UserRole, UserValidator } from '@lovoo-dna/shared';
import { derived } from 'svelte/store';
import { _ } from 'svelte-i18n';
import { orderBy, query, where, doc, getDocs, addDoc, updateDoc, deleteDoc } from 'firebase/firestore';
import { getCollection } from '../firebase';
import { createStore } from '../firebase/adapter';
import { genValidator } from '../lib/utils';
export const collection = getCollection('users');
export function empty() {
    return {
        name: '',
        email: '',
        role: UserRole.Employee,
        active: true,
        comment: '',
        open: 0,
        locale: null,
        photoURL: null,
        scheduledForDeletion: null,
        unread: [],
        channel: UserChannel.Slack,
        changelog: null,
    };
}
const validate = genValidator(UserValidator);
// Queries & Derived
export const all = createStore(query(collection, orderBy('name')));
export const one = derived(all, ($all) => (id) => {
    if (!$all)
        return null;
    return $all.find((u) => u.id === id);
});
// Mutations
export async function add(user) {
    validate(user);
    const existing = await getDocs(query(collection, where('email', '==', user.email)));
    if (!existing.empty)
        throw new Error(`User with email: ${user.email} already exists`);
    return addDoc(collection, user);
}
export async function update(id, data) {
    // @ts-ignore
    delete data['id'];
    if (!data.hasOwnProperty('scheduledForDeletion')) {
        data.scheduledForDeletion = null;
    }
    validate(data);
    return updateDoc(doc(collection, id), data);
}
export async function remove(id) {
    return deleteDoc(doc(collection, id));
}
export const getUserName = derived([all, _], ([all, _]) => {
    function getUserNameFN(id) {
        const notFound = _('descriptions.deletedAnonymous');
        const findOne = (id) => { var _a; return ((_a = all === null || all === void 0 ? void 0 : all.find((u) => u.id === id)) === null || _a === void 0 ? void 0 : _a.name) || notFound; };
        return Array.isArray(id) ? id.map(findOne) : findOne(id);
    }
    return getUserNameFN;
});
