<script lang="ts">
	import { Button, TextArea, TileGroup, RadioTile, Toggle } from 'carbon-components-svelte'
	import { _ } from 'svelte-i18n'
	import Right from 'carbon-icons-svelte/lib/ArrowRight32'
	import Left from 'carbon-icons-svelte/lib/ArrowLeft32'
	import StarFilled from 'carbon-icons-svelte/lib/StarFilled32'

	import type { FeedbackResult } from '@lovoo-dna/shared'
	import { FeedbackFrequencyValues, DNA_VALUES_V4 } from '@lovoo-dna/shared'

	import { decodeScore } from '../../../../../lib/dna'
	import Gif from './Gif.svelte'
	import RatingComment from '../../../components/RatingComment.svelte'
	import InlineError from '../../../../../components/InlineError.svelte'

	export let backward: () => void
	export let forward: () => void
	export let result: FeedbackResult
	export let to: string
	export let error: Error | null = null

	const gifs = [
		'3oEdva9BUHPIs2SkGk',
		'nEMmyUp4hl1QOzovKh',
		'KJ1f5iTl4Oo7u',
		'26gsjCZpPolPr3sBy',
		'YRuFixSNWFVcXaxpmX',
		'lvOnlEYunAwOkHjgmU',
		'qHho9D3nk3nS8',
		'mgqefqwSbToPe',
		'tEaDT85En43i8',
		'vCKC987OpQAco',
		'eiRA0TL4Hlx0XPrI7F',
		'26BRBKqUiq586bRVm',
		'4GRRBtKrdiFDa',
		'xHMIDAy1qkzNS',
		'yyZRSvISN1vvW',
		'3o751YVqovWIxqJC5q',
		'cEODGfeOYMRxK',
		'3ov9jSmllAIKuthAe4',
		'xDpB3lRInUYla',
		'3ohhwi25ISXC7Z4tMs',
		'IcjQNoFcxRuZVBePw2',
		'3otPoS81loriI9sO8o',
		'l522kgooW2Qo9xtK5Z',
		'H4zC1A2FZ0ViA6GcxD',
		'3o6nV9IUiNn1LCjSak',
	]
</script>

<h2>{$_('common.summary')}</h2>
{#each DNA_VALUES_V4 as key, i}
	{#if i !== 0}
		<hr />
	{/if}
	{#if result.ratings['version'] === 4}
		<div class="flex mv4">
			<div class="percentage mr3 mt2 flex flex-column items-center">
				<div style="color: hsl({result.ratings[key].rating * 115}, 100%, 43%)">
					<StarFilled />
				</div>
				{decodeScore(result.ratings[key].rating)}
			</div>
			<div>
				<h4>{$_(`dna.${key}.title`)}</h4>
				<RatingComment rating={result.ratings[key]} />
			</div>
		</div>
	{/if}
{/each}

<br /><br /><br />
<div class="frequency">
	<TileGroup legend={$_('dna.frequency.question', { values: { name: to } })} bind:selected={result.frequency}>
		{#each FeedbackFrequencyValues as value}
			<RadioTile {value}>{$_(`dna.frequency.values.${value}`)}</RadioTile>
		{/each}
	</TileGroup>
</div>

<br /><br /><br />
<TextArea rows={2} labelText={$_('dna.misc.notesQuestion', { values: { name: to } })} bind:value={result.note} />

<br /><br /><br />
<Toggle
	labelText={$_('actions.submitAnonymously')}
	labelA={$_('common.no')}
	labelB={$_('common.yes')}
	bind:toggled={result.anonymous}
/>

<Gif {gifs} class="mt4" />

<div class="flex justify-end mt4">
	<Button on:click={backward} icon={Left} kind="ghost">{$_('actions.back')}</Button>
	<Button on:click={forward} icon={Right}>{$_('actions.submitFeedback')}</Button>
</div>

<InlineError {error} />

<style>
	.percentage {
		font-size: 1.25em;
		width: 4.5rem;
	}

	.percentage :global(svg) {
		width: 3em;
		height: 3em;
	}
</style>
