<script lang="ts">
	import { ClickableTile, Button } from 'carbon-components-svelte'
	import type { CarbonIcon } from 'carbon-icons-svelte'

	export let button: string
	export let icon: typeof CarbonIcon
</script>

<ClickableTile {...$$restProps} class="mv3" on:click>
	<div class="flex justify-between items-center">
		<div class="flex">
			<svelte:component this={icon} class="mr3" />
			<p>
				<slot />
			</p>
		</div>
		<Button size="small">{button}</Button>
	</div>
</ClickableTile>
