<script lang="ts" context="module">
	import { _ } from 'svelte-i18n'
	import type { LineChartOptions } from '@carbon/charts/interfaces'

	export type PlotData = Record<string, string | number | Date>[]
	export type PlotOptions = Partial<LineChartOptions>
</script>

<script lang="ts">
	import { ScaleTypes } from '@carbon/charts/interfaces'
	import { LineChart } from '@carbon/charts-svelte'
	import { merge } from 'lodash'
	import Spinner from '../../../../components/Spinner.svelte'

	export let data: PlotData
	export let options: PlotOptions | undefined = undefined

	const defaults: LineChartOptions = {
		axes: {
			bottom: {
				mapsTo: 'date',
				scaleType: ScaleTypes.TIME,
			},
			left: {
				domain: [0, 100],
				mapsTo: 'value',
				scaleType: ScaleTypes.LINEAR,
			},
		},
		points: { radius: 5 },
		curve: 'curveMonotoneX',
		height: '100%',
		tooltip: { showTotal: false },
		legend: { enabled: false },
	}
</script>

{#if data}
	<slot />
	<div class="mt3">
		{#if data.length}
			<LineChart {data} options={merge(defaults, options)} />
		{:else}
			<div class="h-100 w-100 flex items-center justify-center">
				<b>{$_('descriptions.noData')}</b>
			</div>
		{/if}
	</div>
{:else}
	<Spinner />
{/if}

<style>
	div {
		height: 20rem;
		margin-top: -2rem;
	}
</style>
