<script lang="ts">
	import { groupBy, mapKeys, mapValues } from 'lodash'
	import { Tabs, Tag } from 'carbon-components-svelte'
	import { Alignments, LegendPositions } from '@carbon/charts/interfaces'
	import { _ } from 'svelte-i18n'

	import type { PlotOptions } from '../dna/Plot.svelte'
	import Avatar from '../../../../components/Avatar.svelte'
	import PlotBox from '../dna/PlotBox.svelte'
	import Spinner from '../../../../components/Spinner.svelte'
	import TabWithoutLink from '../../../../components/TabWithoutLink.svelte'

	import { ownManagerGroups, ownManagerOrObserverGroups } from '../../../../store/groups'
	import { ownManagerOrObserverRounds } from '../../../../store/rounds'
	import { aggregateUserRounds, DNAWithSummary } from '../../../../lib/dna'
	import { getUserName } from '../../../../store/users'

	const options: PlotOptions = {
		legend: { enabled: true, alignment: Alignments.CENTER, position: LegendPositions.BOTTOM },
	}
	const types = DNAWithSummary
	let selected: number
	let byDNA: Record<string, { group: string; date: Date; value: number }[]> | null = null
	$: groups = $ownManagerOrObserverGroups
	$: group = groups && groups[selected]
	$: rounds = $ownManagerOrObserverRounds
	$: managerOfCurrentGroup = !!$ownManagerGroups?.find((g) => g.id === group?.id)

	$: if (groups && group && rounds) {
		byDNA = null
		const byRound = rounds.filter((round) => round.group === group!.id)
		const byEmployee = groupBy(byRound, (r) => r.employee)
		const byEmployeeAggregated = mapValues(byEmployee, aggregateUserRounds)
		const byEmployeeAggregatedNames = mapKeys(byEmployeeAggregated, (_, key) => $getUserName(key))

		byDNA = Object.fromEntries(
			types.map((value) => {
				const data = Object.entries(byEmployeeAggregatedNames)
					.map(([key, entry]) => entry.data.filter((d) => d.group === value).map((entry) => ({ ...entry, group: key })))
					.flat(1)
				return [value, data]
			})
		)
	}
</script>

{#if groups}
	<Tabs class="mb3 mt4" bind:selected>
		{#each groups as group (group.id)}
			<TabWithoutLink label={group.name} />
		{/each}
	</Tabs>

	{#if group}
		<div class="mt5">
			<div class="flex items-center mb3">
				<h3>Members</h3>
				<div class="ml3 mt1">
					<Tag type="high-contrast">{$_(managerOfCurrentGroup ? 'common.manager' : 'common.observer')}</Tag>
				</div>
			</div>
			{#each group.members as member (member)}
				<Avatar class="mr3" id={member} href="/dna/{member}" />
			{/each}
		</div>
		<h3 class="mt5 mb3">{$_('common.dashboard')}</h3>
		{#each types as type}
			{#if byDNA}
				<PlotBox class="mb4" {type} {options} data={byDNA[type]} />
			{/if}
		{/each}
	{/if}
{:else}
	<Spinner />
{/if}
