<script lang="ts">
	import { Tile } from 'carbon-components-svelte'
	import { sample } from 'lodash'

	export let gifs: string[]
	const gif = sample(gifs)
</script>

<Tile {...$$restProps}>
	<img src="https://media1.giphy.com/media/{gif}/giphy.webp" alt="gif" />
</Tile>

<style>
	img {
		width: auto;
		height: 10em;
		object-fit: contain;
		object-position: center;
		display: block;
		margin: auto;
	}
</style>
