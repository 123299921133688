<script lang="ts">
	import { Button, Dropdown } from 'carbon-components-svelte'
	import { _ } from 'svelte-i18n'

	import { User, UserChannel } from '@lovoo-dna/shared'
	import InlineError from '../../../components/InlineError.svelte'
	import { notify } from '../../../components/Notifications.svelte'
	import { updateUser } from '../../../firebase/functions'
	import { AvailableLanguages } from '../../../locale'
	import { me } from '../../../store/auth'

	const AUTO = 'auto'

	let loading = false
	let error: Error | null = null
	let init = false

	const langItems = [AUTO, ...AvailableLanguages].map((entry) => ({
		id: entry,
		text: $_('languages.' + entry),
	}))
	let selectedLanguage: string = AUTO

	const channelItems: { id: UserChannel; text: string }[] = [
		{ id: UserChannel.Slack, text: 'Slack' },
		{ id: UserChannel.Mail, text: 'E-Mail' },
	]
	let selectedChannel = UserChannel.Slack

	$: if (!init && $me) {
		init = true
		selectedLanguage = $me.locale ?? AUTO
		selectedChannel = $me.channel
	}

	async function save() {
		try {
			loading = true
			error = null
			const locale: User['locale'] = selectedLanguage === AUTO ? null : selectedLanguage
			await updateUser({ locale, channel: selectedChannel })
			notify({
				title: $_('common.saved'),
				kind: 'info',
			})
		} catch (e) {
			error = e
		} finally {
			loading = false
		}
	}
</script>

<div class="center">
	<h1>{$_('common.account')}</h1>
	{#if $me}
		<h2 class="mt4">{$me.name}</h2>

		<fieldset disabled={loading}>
			<br />
			<Dropdown
				titleText={$_('descriptions.methodOfCommunication')}
				bind:selectedId={selectedChannel}
				items={channelItems}
			/>
			<br />
			<br />
			<Dropdown titleText={$_('common.language')} bind:selectedId={selectedLanguage} items={langItems} />
			<br />
			<br />

			<div class="flex justify-end">
				<Button kind="secondary" on:click={() => window.history.back()}>{$_('actions.back')}</Button>
				<Button on:click={save}>{$_('actions.update')}</Button>
			</div>
			<br />
			<InlineError {error} />
		</fieldset>
	{/if}
</div>

<style>
	div {
		max-width: var(--max-width-xs);
	}
</style>
