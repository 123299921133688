import { httpsCallable } from 'firebase/functions';
import { Functions } from './';
function genFN(name) {
    const fn = httpsCallable(Functions, name);
    return async (data) => {
        const ret = await fn(data);
        return ret.data;
    };
}
export const addPeersToRound = genFN('addPeersToRound');
export const submitFeedback = genFN('submitFeedback');
export const updateUser = genFN('updateUser');
export const updateRound = genFN('updateRound');
export const deleteOwnAuthUser = genFN('deleteOwnAuthUser');
export const getLastSelected = genFN('getLastSelected');
export const sendMessage = genFN('sendMessage');
