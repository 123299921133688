<script lang="ts">
	import { Dropdown } from 'carbon-components-svelte'
	import { _ } from 'svelte-i18n'
	// @ts-ignore
	import { devDependencies } from '../../../../package.json'

	const KEY = 'dna:theme'
	const themes = [
		{ id: 'auto', text: $_('theme.system') },
		{ id: 'white', text: $_('theme.white') },
		{ id: 'g10', text: $_('theme.light') },
		{ id: 'g90', text: $_('theme.dark') },
		{ id: 'g100', text: $_('theme.black') },
	]

	const chartVersion = devDependencies['@carbon/charts-svelte'].slice(1)

	function loadTheme(): string {
		const saved = window.localStorage.getItem(KEY) ?? ''
		return themes.map((t) => t.id).includes(saved) ? saved : 'auto'
	}

	function setTheme(theme: string) {
		// Charts
		const chart = window.document.getElementById('chart-css') as HTMLLinkElement
		const type = theme === 'white' ? '' : '-' + theme
		chart.href = `https://unpkg.com/@carbon/charts@${chartVersion}/styles${type}.min.css`
		// Components
		document.documentElement.setAttribute('theme', theme)
		//Save
		window.localStorage.setItem(KEY, selectedId)
	}

	let selectedId: string = loadTheme()
	let isDark: MediaQueryList = window.matchMedia('(prefers-color-scheme: dark)')
	let listener = (e: MediaQueryListEvent) => {
		setTheme(e.matches ? 'g100' : 'white')
	}

	$: {
		isDark.removeEventListener('change', listener)
		if (selectedId === 'auto') {
			selectedId = isDark.matches ? 'g100' : 'white'
			isDark.addEventListener('change', listener)
		}
		setTheme(selectedId)
	}
</script>

<div>
	<Dropdown size="sm" type="inline" titleText={$_('common.theme')} bind:selectedId items={themes} />
</div>

<style>
	div :global(.bx--dropdown--inline .bx--list-box__menu) {
		min-width: 7rem;
	}
</style>
