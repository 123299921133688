<script lang="ts">
	import type { CarbonIcon } from 'carbon-icons-svelte'

	export let icon: typeof CarbonIcon
	export let message: string
</script>

<div class="center tc">
	<svelte:component this={icon} />
	<div class="mt2 pa0">{message}</div>
</div>
