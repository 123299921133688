<script lang="ts">
	import { _ } from 'svelte-i18n'
	import { ExpandableTile } from 'carbon-components-svelte'

	import { ownGivenCompletedFeedbacks } from '../../../../store/feedbacks'
	import { getUserName } from '../../../../store/users'
	import { extractRatingFromRatings, formatDate, getSortedKeysFromRatings } from '../../../../lib/utils'
	import { decodeScore } from '../../../../lib/dna'

	import BackButton from '../../../../components/BackButton.svelte'
	import DnaWithIcon from '../../../../components/DNAWithIcon.svelte'
	import RatingComment from '../../components/RatingComment.svelte'
</script>

{#if $ownGivenCompletedFeedbacks}
	<BackButton />
	<h1 class="mt5 mb4">{$_('common.feedbacks')}</h1>

	{#each $ownGivenCompletedFeedbacks as feedback}
		<div class="mb3">
			<ExpandableTile>
				<div slot="above" style="height: 2.25rem">
					<div class="flex mb2">
						<h3 class="pa2 f5 username">{$getUserName(feedback.to)}</h3>
						<div class="flex items-center justify-center ml3 ">
							{$_('common.date')}: {formatDate(feedback.created.toDate())}
						</div>
					</div>
				</div>
				<div slot="below" style="height: 100%" class="pv4">
					{#if feedback?.result?.ratings}
						{#each getSortedKeysFromRatings(feedback.result.ratings) as type, i}
							{#if i !== 0}
								<hr class="mv3" />
							{/if}
							<DnaWithIcon class="mb4" {type} />
							{#if feedback.result}
								<div class="flex rating items-center">
									<div class="code f3 ml3 mr4">
										{decodeScore(extractRatingFromRatings(feedback.result.ratings, type)?.rating ?? 0)}
									</div>
									<RatingComment rating={extractRatingFromRatings(feedback.result.ratings, type)} />
								</div>
							{/if}
						{/each}
						{#if feedback.result.note}
							<hr class="mv3" />
							<div class="ml5 pl3">
								<h3>{$_('common.note')}</h3>
								<p>{feedback.result.note}</p>
							</div>
						{/if}
					{/if}
				</div>
			</ExpandableTile>
		</div>
	{/each}
{/if}

<style>
	.username {
		background: var(--cds-text-01, #161616);
		display: inline-block;
		color: var(--cds-ui-background, #ffffff);
	}
</style>
