<script lang="ts">
	import { createEventDispatcher } from 'svelte'
	import type { FeedbackRating } from '@lovoo-dna/shared'
	import { TextArea, Button, TooltipIcon } from 'carbon-components-svelte'
	import { _ } from 'svelte-i18n'
	import Right from 'carbon-icons-svelte/lib/ArrowRight32'
	import Left from 'carbon-icons-svelte/lib/ArrowLeft32'

	import type { AnyDNAValue } from '../../../../../lib/dna'
	import { isStepValid, StepParams } from '../../../../../lib/dna'
	import NormalizedSlider from '../../../../../components/NormalizedSlider.svelte'
	import DnaWithIcon from '../../../../../components/DNAWithIcon.svelte'

	const dispatch = createEventDispatcher()

	export let rating: FeedbackRating
	export let key: AnyDNAValue

	export let forward: () => void
	export let backward: () => void

	function isRatingValid(_: string) {
		// Unused parameter used for triggering on key change
		return isStepValid(rating)[0]
	}

	$: changed = isRatingValid(key as string)
	$: [valid, chars] = isStepValid(rating)
	$: dispatch('validate', changed && valid)
</script>

<DnaWithIcon type={key} />
<p class="mt2">{$_(`dna.${key}.description`)}</p>
<h4 class="mt4 mb2">{$_('common.criteria')}</h4>
<p>{$_(`dna.${key}.criteria`)}</p>

<div class="mv4">
	<NormalizedSlider bind:value={rating.rating} on:change={() => (changed = true)} />
</div>
<br />
<TextArea rows={3} labelText={$_('common.strengths')} bind:value={rating.pros} />
<br />
<TextArea rows={3} labelText={$_('common.potential')} bind:value={rating.cons} />
<br />
<small class:invalid={!valid}>{$_('common.characters')}: <span>{chars}/{StepParams.minChars}</span></small>
<br />
<br />
<div class="flex justify-end">
	<Button on:click={backward} icon={Left} kind="ghost">{$_('actions.back')}</Button>
	<TooltipIcon tooltipText={changed ? $_('descriptions.nextStep') : $_('descriptions.sliderNotTouched')}>
		<Button on:click={forward} icon={Right} disabled={!valid || !changed}>{$_('actions.continue')}</Button>
	</TooltipIcon>
</div>

<style>
	small.invalid {
		color: var(--cds-text-error, #da1e28);
	}
	small span {
		font-family: monospace;
	}
</style>
